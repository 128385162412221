import * as React from 'react'

import { useFormikContext } from 'formik'

const FormError = () => {
  const { errors } = useFormikContext<{ form: '' }>()

  return errors.form ? (
    <div className="text-red-400 mb-1">{errors.form}</div>
  ) : null
}

export default FormError
