import * as React from 'react'

import { useField, useFormikContext } from 'formik'

type Props = {
  options: {
    label: any
    value: any
  }[]
  handleChange: (e: any) => void
  selected: any
}

const PriceTierSelect: React.FC<Props> = (props) => {
  const { options, selected, handleChange } = props

  const { setFieldValue } = useFormikContext<any>()

  const [_, meta, helpers] = useField('priceTier')

  const getTip = (amount: number) => {
    if (amount > 100) {
      return 5
    } else if (amount <= 5) {
      return 20
    } else if (amount < 10.01) {
      return 15
    } else if (amount < 30) {
      return 10
    } else if (amount <= 100) {
      return 10
    } else {
      return 0
    }
  }

  return (
    <div className="flex flex-col mb-4">
      <label className="field-label mb-2">SELECT PRICE TIER</label>
      {options.map((option, index) => {
        const isSelected = selected === option.value

        return (
          <div
            key={`${option.label}-${index}`}
            style={{
              borderRadius: '5px',
              background: isSelected ? '#BFE5E9' : undefined,
              cursor: 'pointer',
              userSelect: 'none',
            }}
            className="w-full border-2 mb-1 border-teal-500 flex flex-row"
            onClick={() => {
              helpers.setTouched(true)

              if (!isSelected) {
                handleChange({
                  target: {
                    value: option.value,
                  },
                })

                helpers.setValue(option.value)
                setFieldValue(
                  'tip',
                  Number(option.value) * Number(getTip(option.value) / 100)
                )
              }
            }}
          >
            <div
              style={{
                paddingTop: '6px',
                paddingBottom: '6px',
                minWidth: '100px',
                borderRightColor: '#2dccd3',
              }}
              className="px-4 border-r-2 flex items-center"
            >
              <input
                type="radio"
                name={`${option.label}-${index}`}
                checked={isSelected}
                onChange={(e) => {}}
              />
              <p className={`ml-2 ${isSelected ? 'text-teal-600' : ''}`}>
                ${option.value}
              </p>
            </div>
            <p
              style={{
                paddingTop: '6px',
                paddingBottom: '6px',
              }}
              className={`px-4 flex items-center ${
                isSelected ? 'text-teal-600' : ''
              } field-label m-0`}
            >
              {option.label}
            </p>
          </div>
        )
      })}
      {meta.touched && meta.error ? (
        <div className="field-error">{meta.error}</div>
      ) : null}
    </div>
  )
}

export default PriceTierSelect
