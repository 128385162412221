import * as React from 'react'

import { useLocation } from 'react-router-dom'

import { useAuth } from '../../contexts/Authentication'

const Home = () => {
  const { state } = useAuth()

  const location = useLocation()
  const counter = React.useRef(0)

  React.useEffect(() => {
    const isLocalServer = ['localhost', '127.0.0.1'].includes(
      window.location.hostname
    )

    if (isLocalServer) {
      return
    }

    const interval = setInterval(() => {
      if (!state.auth.key) {
        return
      }

      if (counter.current == 3) {
        window.location.replace('http://kilterrewards.com')
        return
      }

      counter.current += 1
    }, 1000)

    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [location.pathname])

  return (
    <div className="flex justify-center h-full pt-10 h-full">
      <div className="max-w-2xl text-left w-full"></div>
    </div>
  )
}

export default Home
