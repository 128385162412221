import * as React from 'react'

import {
  Route,
  Switch,
  useParams,
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router-dom'

import * as Yup from 'yup'

import { Formik, Form } from 'formik'

import Success from './Success'
import StripeForm from './StripeForm'
import DonationInfo from './DonationInfo'
import FormError from '../../components/FormError'

import API, { URLS } from '../../services/api'

import poweredBy from '../../assets/images/powered-by-stripe.png'

const TERMS_AND_CONDITIONS_URL =
  'https://www.kilterrewards.com/terms-of-service'
const PRIVACY_POLICY = 'https://www.kilterrewards.com/privacy-policy'

const CharityDonationSchema = Yup.object().shape({
  amount: Yup.number()
    .positive('Amount must be a positive number')
    .required('Amount is required'),
  tip: Yup.number(),
})

const ChallengeDonationSchema = Yup.object().shape({
  amount: Yup.number()
    .positive('Amount must be a positive number')
    .required('Amount is required'),
  tip: Yup.number(),
  name: Yup.string().required('Name is a required field'),
  email: Yup.string().email('Invalid email').required('Required'),
  encouragement: Yup.string(),
})

const CharityDonation = ({ p2p }: any) => {
  const params = useParams<any>()
  const history = useHistory<any>()
  const match = useRouteMatch<any>()

  const { id, challenge_id, participant_id, participant_name } = params

  const { pathname } = useLocation()

  const [charity, setCharity] = React.useState<any>({})
  const [challenge, setChallenge] = React.useState<any>({})
  const [isLoading, setIsLoading] = React.useState(true)

  const pp_id = React.useMemo(
    () => String(challenge?.payment_processor_id),
    [challenge?.payment_processor_id]
  )

  const loadChallengeInfo = React.useCallback(async () => {
    try {
      setIsLoading(true)

      const { data: challenge } = await API.get(URLS.GET_EVENT_BY_ID, {
        params: { challenge_id: challenge_id },
      })

      setChallenge(challenge)
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  }, [challenge_id])

  const loadCharityInfo = React.useCallback(async () => {
    try {
      setIsLoading(true)

      const { data: charity } = await API.get(URLS.GET_CHARITY_BY_ID, {
        params: { charity_id: id },
      })

      setCharity(charity)
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  }, [id])

  React.useEffect(() => {
    if (challenge_id) {
      loadChallengeInfo()
    }

    loadCharityInfo()
  }, [id, loadCharityInfo, loadChallengeInfo])

  const getHeadingTitle = () => {
    if (pathname.endsWith('success')) {
      return 'Thank You'
    }

    return 'Donate Now'
  }

  const challengeHasEnded = () =>
    challenge && challenge.end_fundraising && new Date(challenge.end_fundraising) <= new Date()

  const getSubHeading = () => {
    if (challengeHasEnded()) {
      return 'Sorry, this event event has ended.'
    }

    if (pathname.endsWith('success')) {
      return ''
    }

    return participant_id
      ? `Please help support ${participant_name} with their goal!`
      : ''
  }

  const createJGPayment = async (values: any, setErrors: Function) => {
    const payload = {
      charity_id: charity?.id,
      event_id: Number(challenge_id),
      subtotal: Number(values?.amount),
      donation_type: p2p === true ? 1 : 0,
      users_id: p2p === true ? Number(participant_id) : undefined,
      sponsor_email: values?.email ? values?.email : undefined,
      sponsor_name: values?.name ? values?.name : undefined,
      encouragement: values?.encouragement ? values?.encouragement : undefined,
    }

    try {
      const { data } = await API.post(`${URLS.JG_CREATE_PAYMENT}`, payload)

      if (data?.error) {
        return
      }

      const redirect_url = data?.redirect_url

      const search = new URLSearchParams(redirect_url)

      const exitUrl = search.get('exitUrl')

      if (!exitUrl) {
        return
      }

      const _exitUrl = new URL(exitUrl)

      const newExitUrl = `${window.location.origin}${_exitUrl.pathname}${_exitUrl.search}`

      search.set('exitUrl', encodeURIComponent(newExitUrl))

      const _redirect_url = decodeURIComponent(search.toString())

      window.open(_redirect_url, '_self')
    } catch (error: any) {
      error =
        typeof error?.Message === 'string'
          ? error?.Message
          : 'Something went wrong, please try again'

      setErrors({ form: error })
    }
  }

  return (
    <div className="flex flex-col justify-center items-center py-12">
      <div>
        <h1 className="text-5xl uppercase leading-tight font-bold text-center tracking-widest mb-4">
          {getHeadingTitle()}
        </h1>
      </div>
      <div className="card shadow-none max-w-2xl pb-10 sm:p-6">
        <Formik
          initialValues={{
            amount: 0,
            tip: 0,
            name: '',
            email: '',
            encouragement: '',
          }}
          validationSchema={
            participant_id ? ChallengeDonationSchema : CharityDonationSchema
          }
          onSubmit={async (values, helpers) => {
            if (pp_id === '1') {
              await createJGPayment(values, helpers.setErrors)

              return
            }

            history.push({
              pathname: match.url + '/stripe',
              state: {},
            })
          }}
        >
          <Form>
            <DonationInformation
              charity={charity}
              challenge={challenge}
              isLoading={isLoading}
              subTitle={getSubHeading()}
              participant_id={participant_id}
            />
            {challengeHasEnded() || isLoading ? null : (
              <div className="flex justify-center">
                <div className="max-w-md">
                  <Switch>
                    <Route path={match.path} exact>
                      <DonationInfo p2p={p2p} pp_id={pp_id} />
                      <Notes hideAcknowledgement />
                    </Route>
                    <Route path={`${match.path}/stripe`}>
                      <StripeForm charity={charity} p2p={p2p} />
                      <Notes isStripe />
                    </Route>
                    <Route path={`${match.path}/success`}>
                      <Success
                        charity={charity}
                        participantName={participant_name}
                        p2p={p2p}
                      />
                      <Notes hideAcknowledgement />
                    </Route>
                  </Switch>

                  <FormError />
                </div>
              </div>
            )}
          </Form>
        </Formik>
      </div>
    </div>
  )
}

const DonationInformation = ({
  challenge,
  charity,
  isLoading,
  subTitle,
  participant_id,
}: any) => {
  if (window.location.pathname.includes('/success')) {
    return null
  }

  const data = !!participant_id ? challenge : charity

  return (
    <>
      {isLoading ? (
        <div className="h-40" />
      ) : (
        <>
          <div className="charity-info mb-6 flex-col items-center justify-center">
            <img
              src={data.logo}
              className="object-contain w-full"
              style={{
                maxHeight: '220px',
              }}
            />
            <h2 className="text-xl text-center mt-2">{data.name}</h2>
          </div>
          <div className="flex justify-center">
            <div className="flex-col max-w-lg">
              {subTitle && (
                <div className="mt-4 mb-4">
                  <span className="text-center text-lg block">{subTitle}</span>
                </div>
              )}
              <div>
                <p className="text-center text-lg block mb-4">
                  Select a one-time donation amount supporting {charity?.name}{' '}
                  {charity?.mission_statement
                    ? `with their
          purpose of:`
                    : ''}
                </p>
                <p className="text-center text-lg block mt-2 mb-8">
                  {charity?.mission_statement || ''}
                </p>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}

const Notes = ({
  isStripe,
  hideAcknowledgement,
}: {
  isStripe?: boolean
  hideAcknowledgement?: boolean
}) => (
  <div className="notes flex-col justify-center leading-tight text-sm items-center mx-20">
    {isStripe && (
      <>
        <div className="text-center">
          <svg
            className="mb-2 opacity-50 inline-block"
            width="18"
            height="18"
            viewBox="0 0 17.406 17.407"
          >
            <g id="information">
              <path d="M8.7,0a8.7,8.7,0,1,0,8.7,8.7A8.713,8.713,0,0,0,8.7,0Zm0,15.824A7.121,7.121,0,1,1,15.824,8.7,7.129,7.129,0,0,1,8.7,15.824Z" />
              <path
                d="M146.057,70a1.055,1.055,0,1,0,1.055,1.055A1.056,1.056,0,0,0,146.057,70Z"
                transform="translate(-137.354 -66.308)"
              />
              <path
                d="M150.791,140a.791.791,0,0,0-.791.791v4.747a.791.791,0,0,0,1.582,0v-4.747A.791.791,0,0,0,150.791,140Z"
                transform="translate(-142.088 -132.615)"
              />
            </g>
          </svg>
        </div>
        <div className="opacity-50 text-center">
          Donations are tax deductible based on an acknowledgement letter from
          the charity.
        </div>
      </>
    )}
    {!hideAcknowledgement && (
      <div className="terms-and-conditions opacity-50 text-center">
        By continuing, you acknowledge Kilter's{' '}
        <a
          className="href-link"
          href={TERMS_AND_CONDITIONS_URL}
          target="_blank"
        >
          Terms of Service
        </a>{' '}
        &{' '}
        <a className="href-link" href={PRIVACY_POLICY} target="_blank">
          Privacy Policy
        </a>
        . This link and donation page is publicly accessible.
      </div>
    )}
    {isStripe && <img className="powered-by-stripe" src={poweredBy} />}
  </div>
)

export default CharityDonation
