import * as React from 'react'

const Button = ({
  isLoading,
  children,
  ...props
}: { isLoading?: boolean } & any) => {
  return (
    <button className="button" {...props}>
      {isLoading ? (
        <div className="loader ease-linear rounded-full border-2 border-t-2 border-gray-100 h-6 w-6"></div>
      ) : (
        children
      )}
    </button>
  )
}

export default Button
