import * as React from 'react'

import * as Yup from 'yup'

import {
  Elements,
  CardElement,
  ElementsConsumer,
} from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'

import { Form, Formik, useFormikContext } from 'formik'

import {
  useParams,
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router-dom'

import API, { URLS } from '../../services/api'

import { useAuth } from '../../contexts/Authentication'

import Button from '../../components/Button'
import InputField from '../../components/InputField'
import SelectField from '../../components/SelectField'

import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete'

const stripePromise = loadStripe(process.env.STRIPE_PUBLISHABLE_KEY as any)

const Schema = Yup.object().shape({
  street_address: Yup.string().required('Street is required.'),
  city: Yup.string().required('City is required.'),
  state: Yup.string().required('State is required.'),
  zip: Yup.string().required('Zip is required.'),
  country: Yup.string().required('Country is required.'),
})

const PaymentInfo = ({ charity, p2p }: any) => {
  const history = useHistory()
  const match = useRouteMatch()
  const location = useLocation()

  const { state } = useAuth()
  const { participant_id, challenge_id } = useParams<any>()

  const [error, setError] = React.useState<string | undefined>()
  const [paymentIntent, setPaymentIntent] = React.useState<any>()

  const {
    values: formikValues,
  }: {
    values: {
      amount: '' | number
      tip: number
      challenge_id?: number
      participant_id?: number
      name?: string
      email?: string
      encouragement?: string
    }
    isValid: boolean
  } = useFormikContext<any>()

  const amount = Number(formikValues.amount)
  const tip = amount * (Number(Number(formikValues.tip).toFixed(2)) / 100)
  const total = Number((amount + tip).toFixed(2))

  const createPaymentIntent = async () => {
    const url = p2p
      ? URLS.CREATE_P2P_PAYMENT_INTENT
      : URLS.CREATE_PAYMENT_INTENT
    const encouragementField = formikValues.encouragement
      ? { encouragement: formikValues.encouragement }
      : {}
    const { data, error }: any = await API.post(url, {
      subtotal: amount,
      tip,
      total,
      charity_id: charity.id,
      ...encouragementField,
      ...(challenge_id ? { challenge_id: Number(challenge_id) } : {}),
      ...(p2p
        ? {
            users_id: Number(participant_id),
            sponsor_email: formikValues.email,
          }
        : {}),
    }).catch((e) => {
      if (e.error && e.Message) {
        return { error: e.Message.replace('Error: ', '') }
      }
      return { error: 'Something went wrong. Try again later.' }
    })

    if (data) {
      setPaymentIntent(data)
    } else if (error) {
      setError(error)
    }
  }

  React.useEffect(() => {
    if (!total) {
      history.replace(location.pathname.replace('/stripe', ''))
      return
    }
    createPaymentIntent()
  }, [total])

  return (
    <ElementsConsumer>
      {({ stripe, elements }) => (
        <Formik
          initialValues={{
            street_address: '',
            city: '',
            state: '',
            zip: '',
            country: '',
          }}
          validateOnChange={false}
          validationSchema={Schema}
          onSubmit={async (values) => {
            const card: any = elements?.getElement(CardElement)
            const result = await stripe?.confirmCardPayment(paymentIntent, {
              payment_method: {
                card,
                billing_details: {
                  name:
                    formikValues.name ||
                    state.userInfo.first_name +
                      ' ' +
                      (state.userInfo.last_name || ''),
                  email: state.userInfo.email,
                },
              },
            })
            if (result?.error) {
              setError(
                result.error.message || 'Something went wrong. Try again later.'
              )
            } else if (
              paymentIntent &&
              result?.paymentIntent &&
              result?.paymentIntent.status === 'succeeded'
            ) {
              const billingRes = await API.post(URLS.SET_BILLING_INFO, {
                payment_intent_id: paymentIntent.split('_secret')[0],
                ...values,
                full_name:
                  formikValues.name ||
                  state.userInfo.first_name +
                    ' ' +
                    (state.userInfo.last_name || ''),
              })
              history.push({
                pathname: match.url.replace('stripe', 'success'),
                state: {},
              })
            }
          }}
        >
          {({
            errors,
            values,
            setValues,
            handleSubmit,
            isSubmitting,
            setFieldError,
            setFieldValue,
          }) => {
            return (
              <Form>
                <h5 className="text-xl my-3 font-semibold">Billing Address</h5>
                <PlacesAutocomplete
                  value={values.street_address}
                  onChange={(text) => setFieldValue('street_address', text)}
                  searchOptions={{
                    componentRestrictions: {
                      country: ['us', 'ca', 'ie', 'uk'],
                    },
                    types: ['address'],
                  }}
                  debounce={400}
                  onSelect={async (address) => {
                    try {
                      const result = await geocodeByAddress(address)
                      const billingObj: Record<string, string> = {}
                      const componentForm: Record<string, string> = {
                        street_number: 'short_name',
                        route: 'long_name',
                        locality: 'long_name',
                        administrative_area_level_2: 'long_name',
                        administrative_area_level_1: 'short_name',
                        country: 'short_name',
                        postal_code: 'short_name',
                      }
                      if (result && result[0]) {
                        result[0].address_components.forEach((comp: any) => {
                          const addressType = comp.types[0]
                          if (componentForm[addressType]) {
                            const val = comp[componentForm[addressType]]

                            billingObj[addressType] = val
                          }
                        })
                        setValues({
                          street_address: `${billingObj.street_number || ''}${
                            billingObj.street_number && billingObj.route
                              ? ' '
                              : ''
                          }${billingObj.route || ''}`,
                          city:
                            billingObj.locality ||
                            billingObj.administrative_area_level_2 ||
                            '',
                          state: billingObj.administrative_area_level_1 || '',
                          zip: billingObj.postal_code || '',
                          country: billingObj.country || '',
                        })
                      }
                    } catch (e) {
                      setFieldError('street_address', e)
                    }
                  }}
                >
                  {({
                    loading,
                    suggestions,
                    getInputProps,
                    getSuggestionItemProps,
                  }) => (
                    <div className="places-field-group">
                      <div className="field-group">
                        <label className="field-label">Street</label>
                        <input
                          className="field"
                          {...getInputProps({
                            placeholder: 'Search Address',
                          })}
                          style={{ background: '#f1f1f1' }}
                        />
                        {errors.street_address ? (
                          <div className="field-error">
                            {errors.street_address}
                          </div>
                        ) : null}
                      </div>
                      <div className="autocomplete-dropdown-container">
                        {loading && <div>Loading...</div>}
                        {suggestions.map((suggestion) => {
                          const className = suggestion.active
                            ? 'px-5 py-5 w-full'
                            : 'px-5 py-5 w-full'
                          // inline style for demonstration purpose
                          const style = suggestion.active
                            ? {
                                backgroundColor: '#fafafa',
                                cursor: 'pointer',
                              }
                            : {
                                backgroundColor: '#ffffff',
                                cursor: 'pointer',
                              }
                          return (
                            <div
                              {...getSuggestionItemProps(suggestion, {
                                className,
                                style,
                              })}
                            >
                              <span>{suggestion.description}</span>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  )}
                </PlacesAutocomplete>
                <InputField
                  label="City"
                  type="text"
                  name="city"
                  style={{ background: '#f1f1f1' }}
                />
                <InputField
                  label="State"
                  type="text"
                  name="state"
                  style={{ background: '#f1f1f1' }}
                />
                <InputField
                  label="Zip"
                  type="text"
                  name="zip"
                  style={{ background: '#f1f1f1' }}
                />
                <SelectField
                  label="Country"
                  type="text"
                  name="country"
                  options={[
                    {
                      label: 'United States',
                      value: 'US',
                    },
                    {
                      label: 'Canada',
                      value: 'CA',
                    },
                    {
                      label: 'United Kingdom',
                      value: 'GB',
                    },
                    {
                      label: 'Ireland',
                      value: 'IE',
                    },
                  ]}
                  style={{ background: '#f1f1f1' }}
                />
                {error ? (
                  <div className="text-red-400 mb-1">{error}</div>
                ) : null}
                <div className="field-group">
                  <div className="field-label">Card Details</div>
                  <div className="field" style={{ background: '#f1f1f1' }}>
                    <CardElement
                      options={{
                        style: {
                          base: {
                            fontSize: '16px',
                            color: '#424770',
                            '::placeholder': {
                              color: '#aab7c4',
                            },
                          },
                          invalid: {
                            color: '#9e2146',
                          },
                        },
                      }}
                    />
                  </div>
                </div>
                <div className="field-group mx-10 my-10">
                  <Button
                    type="submit"
                    className="button-variant"
                    disabled={!stripe || !paymentIntent}
                    isLoading={isSubmitting}
                    onClick={handleSubmit}
                  >
                    Donate
                  </Button>
                </div>
              </Form>
            )
          }}
        </Formik>
      )}
    </ElementsConsumer>
  )
}

export default (props: any) => (
  <Elements stripe={stripePromise}>
    <PaymentInfo {...props} />
  </Elements>
)
