import * as React from 'react'

import { Link } from 'react-router-dom'

import API, { URLS } from '../../services/api'

const Charity = () => {
  const [search, setSearch] = React.useState<any>('')
  const [charities, setCharities] = React.useState<any>([])

  const fetchCharities = async () => {
    const charities = await API.get(URLS.GET_CHARITIES).then(({ data }) => data)

    setCharities(charities)
  }

  React.useEffect(() => {
    fetchCharities()
  }, [])

  const filteredCharities =
    search.length > 0
      ? charities.filter((charity: any) => {
          return charity.name.toLowerCase().includes(search.toLowerCase())
        })
      : charities

  return (
    <>
      <div className="flex pt-4 px-4">
        <input
          className="border w-full text-lg p-4"
          placeholder="Search Charity"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          type="text"
        />
      </div>
      <div className="flex pt-4 h-full px-4">
        <div className="flex flex-wrap -mb-4 -mx-4 w-full">
          {filteredCharities.map((charity: any, index: number) => (
            <div
              className="mb-4 w-full sm:w-full md:w-1/2 lg:w-1/3 px-4"
              key={`${charity.id}-${index}`}
            >
              <div className="card w-full max-w-full">
                <div className="charity-info flex-col items-center justify-center flex">
                  <img
                    loading="lazy"
                    src={charity.logo}
                    className="h-24 rounded-sm"
                  />
                  <h2 className="text-xl mt-2">{charity.name}</h2>
                  <Link
                    to={`/charity/${charity.id}`}
                    className="button mt-4"
                    type="submit"
                  >
                    Donate
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

export default Charity
