import * as React from 'react'

export default ({ p2p, charity, participantName }: any) => (
  <p className="text-center text-lg block mb-8 mt-4">
    {p2p ? (
      <>
        Thank you for supporting {participantName}'s personal wellness goal in
        this event with a donation to {charity.name}!
        <br />
        <br />
        Check your email for a confirmation and a link to a leaderboard to
        follow their progress.
      </>
    ) : (
      `Thank you for donating to ${charity.name}!`
    )}
  </p>
)
