import * as React from 'react'

import { useField, useFormikContext } from 'formik'

import Button from '../../components/Button'
import InputField from '../../components/InputField'

const getTip = (amount: number) => {
  if (amount > 100) {
    return 5
  } else if (amount <= 5) {
    return 20
  } else if (amount < 10.01) {
    return 15
  } else if (amount < 30) {
    return 10
  } else if (amount <= 100) {
    return 10
  } else {
    return 0
  }
}

const DonationInfo = ({ p2p, pp_id }: any) => {
  const { values, isSubmitting, setFieldValue } = useFormikContext<any>()

  const [isChecked, setIsChecked] = React.useState(true)

  const fee = Number(values.amount) * (Number(getTip(values.amount)) / 100)
  const total = !isChecked ? Number(values.amount) : Number(values.amount) + fee

  React.useEffect(() => {
    if (pp_id === '1') {
      setIsChecked(false)
    }
  }, [pp_id])

  return (
    <div style={{ minWidth: '350px' }}>
      <PillSelectField
        label="Select a One-time Amount"
        name="amount"
        options={[
          { value: 100, label: '$100' },
          { value: 50, label: '$50' },
          { value: 25, label: '$25' },
          { value: 10, label: '$10' },
        ]}
      />
      {p2p && (
        <>
          <InputField
            label="SHARE YOUR WORDS OF ENCOURAGEMENT"
            labelProps={{
              className: 'text-xl my-3 font-semibold',
            }}
            name="encouragement"
            style={{ background: '#f1f1f1' }}
          />
          <h5 className="mt-8 text-xl my-3 font-semibold">YOUR INFORMATION</h5>
          <InputField
            label="Name"
            name="name"
            style={{ background: '#f1f1f1' }}
          />
          <InputField
            label="Email"
            name="email"
            style={{ background: '#f1f1f1' }}
          />
        </>
      )}
      {pp_id === '2' && (
        <label className="inline-flex items-center mt-3">
          <input
            type="checkbox"
            defaultChecked={true}
            className="form-checkbox h-5 w-5 text-gray-600"
            onChange={({ target }) => {
              setIsChecked(target.checked)
              setFieldValue('tip', target.checked ? getTip(values.amount) : 0)
            }}
          />
          <span className="ml-2" style={{ color: '#515253' }}>
            I want to cover the fees of $
            {(fee || 0)?.toFixed(2)?.toLocaleString()} associated to my
            donation.
          </span>
        </label>
      )}
      {pp_id === '2' && (
        <p className="text-center my-4 font-bold opacity-50">
          Total: ${(total || 0)?.toFixed(2)?.toLocaleString()}
        </p>
      )}
      <div className="field-group mx-10 mt-8">
        {pp_id === '1' ? (
          <Button
            type="submit"
            className="button-variant"
            isLoading={isSubmitting}
          >
            Donate
          </Button>
        ) : (
          <button className="button-variant" type="submit">
            Next
          </button>
        )}
      </div>
    </div>
  )
}

const PillSelectField = ({ label, options, name }: any) => {
  const inputRef = React.useRef<HTMLInputElement>(null)

  const [, , tipHelpers] = useField('tip')
  const [field, meta, helpers] = useField(name)

  const [pillValue, setPillValue] = React.useState<any>(field.value)
  const [otherValue, setOtherValue] = React.useState<any>(undefined)

  React.useEffect(() => {
    const newValue = pillValue === 'other' ? otherValue : pillValue
    if (field.value === newValue) {
      return
    }

    tipHelpers.setValue(
      pillValue === 'other' ? getTip(otherValue) : getTip(pillValue)
    )
    helpers.setValue(pillValue === 'other' ? otherValue : pillValue)
    if (pillValue !== 'other') {
      helpers.setError(false)
    }
    if (newValue === undefined) {
      helpers.setTouched(false)
    }
  }, [pillValue, otherValue])

  React.useEffect(() => {
    if (pillValue === 'other' && inputRef.current) {
      // @ts-ignore
      inputRef!.current!.focus()
    }
  }, [pillValue])

  return (
    <div className="field-group  mt-4">
      <h5 className="text-xl my-3 font-semibold">{label}</h5>
      <div className="flex items-center justify-center flex-wrap">
        {options.map(({ value, label }: any, index: number) => (
          <button
            key={value}
            type="button"
            style={{ marginRight: index === 3 ? 0 : '10px' }}
            className={`field-pill ${
              value === pillValue ? 'field-pill-selected' : ''
            } amount-pill`}
            onClick={() => {
              setPillValue(value)
              if (inputRef.current) inputRef.current.value = ''
            }}
          >
            {label}
          </button>
        ))}
      </div>
      <div className="amount-field-container">
        <div className="amount-field-left">
          <p className="text-sm">USD</p>
        </div>
        <span className="ml-2 text-sm">$</span>
        <input
          className="others-amount"
          type="number"
          placeholder="Put amount for other"
          ref={inputRef}
          onChange={(e) => {
            if (e.target.value.includes('.')) {
              const decimalLength = e.target.value.split('.')[1].length

              if (decimalLength > 2) {
                setPillValue(Number(e.target.value).toFixed(2))
                //@ts-ignore
                inputRef.current.value = Number(e.target.value).toFixed(2)
                return
              }
            }

            setPillValue(e.target.value)
          }}
          step="any"
          onBlur={field.onBlur}
        />
      </div>
      <div className="field-error">{meta.error ? meta.error : null}</div>
    </div>
  )
}

export default DonationInfo
