import * as React from 'react';

import { useHistory } from 'react-router';

import { isMobile } from 'react-device-detect';

import qs from 'query-string';

import { useAuth } from '../../contexts/Authentication';

import appstore from '../../assets/images/appstore.png';
import playstore from '../../assets/images/playstore.png';
import checkmark from '../../assets/images/check_mark.png';
import mobilescreens from '../../assets/images/mobile_image.png';

import axios from 'axios';
import API, { URLS } from '../../services/api';

import { Participation } from 'types/event';

type Status = null | 'success' | 'error' | 'pending';

const KR_EVENTS_PAGE = `https://www.kilterrewards.com/events`;

const AdmissionConfirm: React.FC = () => {
    const { state } = useAuth();

    const history = useHistory();

    const [status, setStatus] = React.useState<Status>(null);
    const [errMsg, setErrMsg] = React.useState<string>('');

    const [link, setLink] = React.useState<string | null>(null);
    const [isLoading, setIsLoading] = React.useState<boolean>(true);
    const [isLoadingLink, setIsLoadingLink] = React.useState<boolean>(true);

    const getEventParticipation = async (id: string) => {
        try {
            const { data } = await API.get<Participation[]>(`${URLS.GET_PARTICIPATIONS}`);

            const participation = data.find(({ challenge }) => challenge.id === parseInt(id));

            return participation ?? null;
        } catch (error) {
            return null;
        }
    };

    const generateDeepLink = async (id: string) => {
        try {
            const participation = await getEventParticipation(id);

            const branchUrl = `https://api2.branch.io/v1/url`;
            const payload = {
                branch_key: `${process.env.BRANCH_KEY}`,
                data: {
                    $og_title: participation?.challenge?.name ?? '',
                    $og_description: participation?.challenge?.description ?? '',
                    $og_image_url: participation?.challenge?.logo ?? '',
                    participation: participation ?? { id },
                    user: {
                        key: state?.auth?.key,
                        firstName: state?.userInfo?.first_name,
                    },
                },
            };
            const config: any = {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
            };

            const { data } = await axios.post<{ url: string }>(branchUrl, payload, config);

            if (data && 'url' in data) {
                setLink(data.url);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoadingLink(false);
        }
    };

    React.useEffect(() => {
        const updateRegistration = async (regPayload: any, eventPayload: any) => {
            try {
                const { data: regResp } = await API.post(
                    `${URLS.JS_REGISTER_EVENT_UPDATE}`,
                    regPayload
                );

                const _isPending = regResp?.data?.payment?.status === 'pending';

                if (_isPending) {
                    setStatus('pending');

                    return;
                }

                const { data: eventResp } = await API.post(`${URLS.V2_EVENT_JOIN}`, eventPayload);

                if (eventResp?.success) {
                    setStatus('success');
                }

                generateDeepLink(eventPayload.challenge_id);
            } catch (error) {
                if (error?.error) {
                    setStatus('error');
                }

                if (error?.Message) {
                    setErrMsg(String(error?.Message));
                }
            } finally {
                setIsLoading(false);
                setIsLoadingLink(false);
            }
        };

        if (!history.location.search) {
            return;
        }
        
        const params = qs.parse(history.location.search);

        const regPayload = {
            charity_id: Number(params.charity_id),
            event_id: Number(params.event_id),
            ext_transaction_id: String(params.jgDonationId),
            payment_id: Number(params.payment_id),
            merch_order_id: params.merch_order_id === '' ? null : Number(params?.merch_order_id),
            reference_id: String(params.reference_id),
            registration_id: Number(params.registration_id),
        };
        
        const eventPayload = {
            team_id: Number(params.team_id),
            join_code: params.join_code,
            challenge_id: Number(params.event_id),
            charity_id: Number(params.charity_id),
            free_admission_code: params.free_admission_code === '<nil>' ? undefined : params?.free_admission_code,
        };
        
        updateRegistration(regPayload, eventPayload);
    }, [history]);

    return (
        <div className="flex flex-col justify-center items-center p-2 pt-12">
            <div className="card max-w-6xl shadow-xl flex flex-row flex-wrap">
                {isLoading || isLoadingLink ? (
                    <div className="loading-container">
                        <h1 className="text-4xl text-center mb-4">Please Wait..</h1>
                        <div className="flex justify-center items-center">
                            <div className="animate-spin rounded-full h-24 w-24 border-b-4 border-gray-900"></div>
                        </div>
                    </div>
                ) : status === 'pending' ? (
                    <div className="flex align container main">
                        <p className="mt-2 text-2xl text-center">
                            Your transaction is currently pending.
                        </p>
                        <p className="mt-2 text-xl text-center">
                            We will send you an email with an update shortly.
                        </p>
                    </div>
                ) : status === 'success' || status === null ? (
                    <>
                        <div className="flex align container main">
                            <img src={checkmark} className="checkmark" />
                            <h1 className="header leading-tight font-bold">EVENT JOINED</h1>
                            {isMobile ? (
                                <div className="mobile-section">
                                    <p className="text-content">
                                        Check out the details for your new event and start earning
                                        points!
                                    </p>
                                    <a
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href={link ?? KR_EVENTS_PAGE}
                                        className="get-app-button leading-tight font-bold"
                                    >
                                        EVENT DETAILS
                                    </a>
                                </div>
                            ) : null}
                            <p className="text-content">
                                {isMobile
                                    ? 'Don’t have the app? Download to start earning points for your event.'
                                    : 'You’re so close! Just download our free app to start earning points for your event.'}
                            </p>
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={link ?? KR_EVENTS_PAGE}
                                className="get-app-button leading-tight font-bold"
                            >
                                GET THE APP
                            </a>
                            <div className="flex justify-center mb-4">
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={
                                        isMobile
                                            ? link ?? KR_EVENTS_PAGE
                                            : 'https://apps.apple.com/us/app/kilter-rewards/id1265336240'
                                    }
                                >
                                    <img className="h-12 mr-1" src={appstore} />
                                </a>
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={
                                        isMobile
                                            ? link ?? KR_EVENTS_PAGE
                                            : 'https://play.google.com/store/apps/details?id=com.kilterrewards.kilterApp&hl=en'
                                    }
                                >
                                    <img className="h-12 ml-1" src={playstore} />
                                </a>
                            </div>
                        </div>
                        <div className="flex-1 container screens-container">
                            <img src={mobilescreens} className="mobile-screens" />
                        </div>
                    </>
                ) : (
                    <>
                        <div className="flex flex-col justify-center items-center w-full">
                            <h1 className="text-4xl text-center">Oops!</h1>
                            <p className="mt-2 text-2xl text-red-500">{errMsg || ''}</p>
                        </div>
                    </>
                )}
            </div>
            <div className="bottom-container">
                <p className="bottom-text">
                    Want your activities to go even further? Check out what else is going on!
                </p>
                <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={KR_EVENTS_PAGE}
                    className="get-app-button leading-tight font-bold"
                >
                    SEE MORE
                </a>
            </div>
        </div>
    );
};

export default AdmissionConfirm;