import React from 'react'
import {
BrowserRouter
} from 'react-router-dom'
import { createRoot } from 'react-dom/client';
const container = document.getElementById('app');
const root = createRoot(container); 
import { Main } from './Main'

import TagManager from 'react-gtm-module'

const tagManagerArgs = {
  gtmId: process.env.GTM_ID || '',
}

TagManager.initialize(tagManagerArgs)

root.render(
  <BrowserRouter>
    <React.StrictMode>
      <Main />
    </React.StrictMode>
  </BrowserRouter>
);
