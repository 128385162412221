import * as React from 'react'

import {
  useState,
  useReducer,
  useContext,
  useCallback,
  createContext,
} from 'react'

import API, { URLS } from '../services/api'

import { State, Action, Dispatch } from './Authentication.props'

const initialState: State = { auth: {}, userInfo: {} }

const AuthStateContext = createContext<State | undefined>(undefined)
const AuthDispatchContext = createContext<Dispatch | undefined>(undefined)

const authReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'LOGGED_IN':
      return { ...state, auth: action.payload }
    case 'USER_DATA_FETCHED':
      return { ...state, userInfo: action.payload }
    case 'LOGGED_OUT':
      return initialState
    default:
      return state
  }
}

export const Provider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, initialState)
  const updateAuth = useCallback(
    (auth: { key: string; hash: string; expires: string }) =>
      dispatch({
        type: 'LOGGED_IN',
        payload: auth,
      }),
    []
  )

  // register axios interceptor
  React.useEffect(
    () => setUpAuthenticationKeyInterceptor(state.auth.key, updateAuth),
    [state.auth.key]
  )

  return (
    <AuthStateContext.Provider value={state}>
      <AuthDispatchContext.Provider value={dispatch}>
        {children}
      </AuthDispatchContext.Provider>
    </AuthStateContext.Provider>
  )
}

export const useAuthState = () => {
  const context = useContext(AuthStateContext)
  if (context === undefined) {
    throw new Error(
      'useAuthState must be used within a Authentication.Provider'
    )
  }

  return context
}

export const useAuthDispatch = () => {
  const context = useContext(AuthDispatchContext)
  if (context === undefined) {
    throw new Error(
      'useAuthDispatch must be used within a Authentication.Provider'
    )
  }

  return context
}

export const useAuth = () => {
  const state = useAuthState()
  const dispatch = useAuthDispatch()

  const signIn = React.useCallback(
    async ({ email, password }) => {
      try {
        const { data } = await API.post(URLS.SIGN_IN, {
          email,
          password,
          type: 1,
        })

        if (data.key) {
          dispatch({ type: 'LOGGED_IN', payload: data })
          setLocalStorage('sessionHash', data.hash, data.expires)

          await fetchUserInfo(data.key)

          return true
        }

        return false
      } catch (error) {
        return false
      }
    },
    [dispatch]
  )

  const requestResetPassword = React.useCallback(
    async ({ email }) => {
      const { data } = await API.post(URLS.REQUEST_RESET_PASSWORD, {
        email,
      })

      return data?.request_sent
    },
    [dispatch]
  )

  const resetPassword = React.useCallback(
    async (payload: { new_password: string; rKey: string }) => {
      const { data } = await API.post(URLS.RESET_PASSWORD, payload, {
        headers: {
          key: payload.rKey,
        },
      })

      return data
    },
    []
  )

  const signUp = React.useCallback(
    async ({ email, password, first, last }) => {
      const res = await API.post(URLS.SIGN_UP, {
        email,
        password,
        first,
        last,
      })

      const data = res.data

      if (data.key) {
        dispatch({ type: 'LOGGED_IN', payload: data })
        setLocalStorage('sessionHash', data.hash, data.expires)

        await fetchUserInfo(data.key)

        return { ok: 'success' }
      }

      return { error: data?.Message || data?.message }
    },
    [dispatch]
  )

  const signOut = React.useCallback(() => {
    dispatch({ type: 'LOGGED_OUT' })
    localStorage.removeItem('sessionHash')
  }, [dispatch])

  const fetchUserInfo = React.useCallback(
    async (key?: string) => {
      try {
        const { data: userInfo } = await API.get(URLS.USER_INFO, {
          headers: { key: key || '' },
        })
        dispatch({ type: 'USER_DATA_FETCHED', payload: userInfo })
      } catch (error) {}
    },
    [dispatch]
  )

  return {
    state,
    signIn,
    fetchUserInfo,
    signOut,
    signUp,
    resetPassword,
    requestResetPassword,
  }
}

export const Consumer: React.FC<any> = ({ children }) => {
  return (
    <AuthStateContext.Consumer>
      {(context) => {
        if (context === undefined) {
          throw new Error(
            'Authentication.Consumer must be used within a Authentication.Provider'
          )
        }

        return children(context)
      }}
    </AuthStateContext.Consumer>
  )
}

export const AuthEffects: React.FC<any> = ({ children }) => {
  const { fetchUserInfo } = useAuth()
  const [isLoading, setIsLoading] = useState(true)

  // initial user data load
  React.useEffect(() => {
    setTimeout(async () => {
      await fetchUserInfo()
      setIsLoading(false)
    }, 0)
  }, [])

  return isLoading ? null : children
}

// - adds key to header
// - try to reauthenticate using hash if key don't exists
const setUpAuthenticationKeyInterceptor = (
  key: string | undefined,
  updateAuth: Function
) => {
  const interceptor = API.interceptors.request.use(async (request) => {
    if (request?.headers?.key) {
      return request
    }

    if (key) {
      //@ts-ignore
      request.headers.key = key
    } else if (
      !~[URLS.CHECK_SESSION_HASH, URLS.SIGN_IN].indexOf(request?.url || '')
    ) {
      // fallback to using hash to get new key
      const sessionHash = getFromLocalStorage('sessionHash')

      if (sessionHash) {
        try {
          const { key, hash, expires } = await API.post(
            URLS.CHECK_SESSION_HASH,
            {
              hash: sessionHash,
            }
          ).then(
            (res) => res.data,
            () => {
              console.log('')
            }
          )

          if (key) {
            updateAuth({ key, hash, expires })
            setLocalStorage('sessionHash', hash, expires)
          }

          //@ts-ignore
          request.headers.key = key
        } catch (error) {
          console.log('')
        }
      }
    }

    return request
  })

  return () => API.interceptors.request.eject(interceptor)
}

const setLocalStorage = (key: string, value: any, expires?: string) =>
  localStorage.setItem(
    key,
    JSON.stringify({
      value,
      expires,
    })
  )

const getFromLocalStorage = (key: string) => {
  const data = localStorage.getItem(key)

  if (!data) {
    return undefined
  }

  try {
    const parsedData = JSON.parse(data)

    // @ts-ignore
    if (!parsedData.expires) {
      return parsedData
    }

    // @ts-ignore
    if (parsedData.expires && new Date() < new Date(parsedData.expires)) {
      return parsedData.value
    }

    return undefined
  } catch {
    return data
  }
}
