import * as React from 'react'

import { useField } from 'formik'

const InputField = ({ label, hint, labelProps = {}, ...props }: any) => {
  const [field, meta, helpers] = useField(props)
  const [showPassword, setShowPassword] = React.useState(false)

  const isVariant = Object.keys(labelProps)?.length !== 0
  const isEmail = props?.name === 'email'
  const isPassword = props?.type === 'password'

  const onChange =
    isEmail || isPassword
      ? (e: React.ChangeEvent<HTMLInputElement>) => {
          helpers.setValue(e.target.value.trim())
        }
      : field.onChange

  return (
    <div className="field-group">
      {isVariant ? (
        <h5 className="field-label" {...labelProps}>
          {label}
        </h5>
      ) : (
        <label className="field-label">{label}</label>
      )}
      <div className="field-group relative justify-center">
        <input
          className="field"
          {...props}
          {...field}
          onChange={onChange}
          style={{ borderWidth: '2px' }}
          type={isPassword ? (showPassword ? 'text' : 'password') : props.type}
        />
        {isPassword && (
          <div
            className="absolute right-0 mr-4 cursor-pointer mt-2"
            onClick={() => setShowPassword((prev) => !prev)}
          >
            <span className="material-symbols-outlined">
              {showPassword ? 'visibility' : 'visibility_off'}
            </span>
          </div>
        )}
      </div>
      {hint ? <div className="field-hint">{hint}</div> : null}
      {meta.touched && meta.error ? (
        <div className="field-error">{meta.error}</div>
      ) : null}
    </div>
  )
}

export default InputField
