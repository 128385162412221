import * as React from 'react'

import * as Yup from 'yup'
import { Form, Formik } from 'formik'

import { Link, useLocation, useHistory } from 'react-router-dom'

import { useAuth } from '../../contexts/Authentication'

import Button from '../../components/Button'
import FormError from '../../components/FormError'
import InputField from '../../components/InputField'

const BRANCH_LINK = 'https://kilter.app.link'

interface RegistrationFormSchema {
  first: string
  last: string
  email: string
  password: string
  confirmPassword: string
}

const RegistrationFormValidationSchema = Yup.object().shape({
  first: Yup.string().required('Required'),
  last: Yup.string().required('Required'),
  email: Yup.string().email('Invalid email').required('Required'),
  password: Yup.string().required('Required'),
  confirmPassword: Yup.string().oneOf(
    [Yup.ref('password'), null as any],
    'Passwords must match'
  ),
})

const Registration = () => {
  const { signUp } = useAuth()

  const history = useHistory()
  const location = useLocation()

  const isUnMounted = React.useRef(false)

  const [isLoading, setIsLoading] = React.useState(false)

  const search = new URLSearchParams(location.search)
  const redirectBack = search.get('redirectBack')

  React.useEffect(
    () => () => {
      isUnMounted.current = true
    },
    []
  )

  const handleRegister = React.useCallback(
    async (values: RegistrationFormSchema, { setErrors }: any) => {
      if (!isUnMounted.current) {
        setIsLoading(true)
      }

      const res = await signUp({
        ...values,
        email: values?.email?.trim(),
        password: values?.password?.trim(),
      })

      if (res.ok === 'success') {
        // redirect
        if (redirectBack) {
          history.replace(redirectBack)
        } else {
          window.location.replace(BRANCH_LINK)
        }
      } else if (~(res.error || '').toLowerCase().indexOf('email')) {
        setErrors({ email: res.error })
      } else {
        setErrors({ form: res.error })
      }
      if (!isUnMounted.current) {
        setIsLoading(false)
      }
    },
    []
  )

  return (
    <div className="flex justify-center items-center pt-12">
      <div className="card">
        <h1 className="text-2xl uppercase leading-tight font-bold text-center mb-8 tracking-widest underlined-header">
          Create your account
        </h1>
        <Formik
          initialValues={{
            first: '',
            last: '',
            email: '',
            password: '',
            confirmPassword: '',
          }}
          validationSchema={RegistrationFormValidationSchema}
          onSubmit={handleRegister}
        >
          <Form>
            <FormError />
            <InputField label="First Name" type="text" name="first" />
            <InputField label="Last Name" type="text" name="last" />
            <InputField label="Email" type="text" name="email" />
            <InputField label="Password" type="password" name="password" />
            <InputField
              label="Confirm Password"
              type="password"
              name="confirmPassword"
            />
            <p className="mt-6">
              By clicking Register, you agree to the{' '}
              <a
                className="text-blue-500 hover:text-blue-400"
                href="http://www.kilterrewards.com/terms-of-service"
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms and Services.
              </a>
            </p>
            <div className="mt-6 flex">
              <Button
                className="button mr-2"
                type="submit"
                isLoading={isLoading}
              >
                Register
              </Button>
            </div>
            <div className="mt-4 flex">
              <p>
                Already have an account?{' '}
                <Link
                  className="text-blue-500 hover:text-blue-400"
                  to={`/login${location.search || ''}`}
                >
                  Sign in
                </Link>
              </p>
            </div>
          </Form>
        </Formik>
      </div>
    </div>
  )
}

export default Registration
