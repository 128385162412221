import * as React from 'react'

import { Link, Route, Switch, useHistory, useLocation } from 'react-router-dom'

import {
  useAuth,
  AuthEffects,
  Provider as AuthenticationProvider,
} from './contexts/Authentication'

import logo from './assets/images/kilter_light_bg.png'

import Home from './screens/Home'
import Login from './screens/Login'
import Charity from './screens/Charity'
import Challenges from './screens/Challenges'
import PrivateRoute from './screens/PrivateRoute'
import Registration from './screens/Registration'
import ResetPassword from './screens/ResetPassword'
import GuestOnlyRoute from './screens/GuestOnlyRoute'
import CharityDonation from './screens/CharityDonation'
import ResetPasswordSet from './screens/ResetPasswordSet'
import ChallengeSuccess from './screens/ChallengeSuccess'
import JoinChallenge from './screens/Challenges/JoinChallenge'
import AdmissionConfirm from './screens/AdmissionConfirm'
import DonationConfirm from './screens/DonationConfirm'

const Header = () => {
  const history = useHistory()
  const location = useLocation()

  const { state, signOut } = useAuth()

  return (
    <div className="h-16 md:h-32 mb-4 bg-white border-b-2 py-2 flex justify-center">
      <div className="flex items-center max-w-5xl w-full justify-between">
        <a href="http://kilterrewards.com">
          <img
            src={logo}
            className="h-12 max-w-xs md:h-24"
            alt="kilterrewards logo"
          />
        </a>
        <div>
          {!!state.auth.key ? (
            <>
              <button
                className="button w-16 sm:w-32 text-sm md:text-lg mr-2"
                onClick={() => {
                  signOut()
                  history.replace('/')
                }}
              >
                Logout
              </button>
            </>
          ) : (
            <>
              <div className="flex">
                {location.pathname !== '/register' ? (
                  <Link
                    to={`/register${location.search || ''}`}
                    className="button w-16 sm:w-32 text-sm md:text-lg mr-2"
                  >
                    Register
                  </Link>
                ) : null}
                {location.pathname !== '/login' ? (
                  <Link
                    to={`/login${location.search || ''}`}
                    className="button w-16 sm:w-32 text-sm md:text-lg mr-2"
                  >
                    Sign In
                  </Link>
                ) : null}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export const Main = () => {
  return (
    <AuthenticationProvider>
      <AuthEffects>
        <Header />
        <div className="flex-1">
          <Switch>
            <Route path="/" exact>
              <Home />
            </Route>
            <GuestOnlyRoute path="/login">
              <Login />
            </GuestOnlyRoute>
            <GuestOnlyRoute path="/request-password-reset">
              <ResetPassword />
            </GuestOnlyRoute>
            <GuestOnlyRoute path="/reset-password">
              <ResetPasswordSet />
            </GuestOnlyRoute>
            <GuestOnlyRoute path="/register">
              <Registration />
            </GuestOnlyRoute>
            <PrivateRoute path="/challenges">
              <Challenges />
            </PrivateRoute>
            <PrivateRoute path="/challenge/:type/:id/join">
              <JoinChallenge />
            </PrivateRoute>
            <PrivateRoute path="/challenge/:type/:id/success">
              <ChallengeSuccess />
            </PrivateRoute>
            <Route path="/charity/:id/challenge/:challenge_id/participant/:participant_id/:participant_name">
              <CharityDonation p2p />
            </Route>
            <PrivateRoute path="/charity/:id/challenge/:challenge_id">
              <CharityDonation p2p={false} />
            </PrivateRoute>
            <PrivateRoute path="/charity/:id">
              <CharityDonation p2p={false} />
            </PrivateRoute>
            <PrivateRoute path="/charity">
              <Charity />
            </PrivateRoute>
            <PrivateRoute path="/admission/confirmation">
              <AdmissionConfirm />
            </PrivateRoute>
            <Route path="/donation/confirmation">
              <DonationConfirm />
            </Route>
            <Route path="/*">404</Route>
          </Switch>
        </div>
      </AuthEffects>
    </AuthenticationProvider>
  )
}
