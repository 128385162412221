import * as React from 'react'

import { useField } from 'formik'

const Checkbox = ({ label, hint, ...props }: any) => {
  const [field] = useField(props)

  return (
    <label className="inline-flex items-center mt-3 mb-3">
      <input
        type="checkbox"
        className="form-checkbox h-5 w-5 text-gray-600"
        checked={field.value}
        {...props}
        {...field}
      />
      <span className="ml-2 text-gray-700">{label}</span>
    </label>
  )
}

export default Checkbox
