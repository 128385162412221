import * as Yup from 'yup'

const shipping = Yup.object().when('sameAsBilling', {
  is: (sameAsBilling: any) => !sameAsBilling,
  then: Yup.object().shape({
    street: Yup.string().required('Street is required.'),
    city: Yup.string().required('City is required.'),
    state: Yup.string().required('State is required.'),
    zip: Yup.string().required('Zip is required.'),
    country: Yup.string().required('Country is required.'),
  }),
})

const billing = Yup.object().shape({
  street_address: Yup.string().required('Street is required.'),
  city: Yup.string().required('City is required.'),
  state: Yup.string().required('State is required.'),
  zip: Yup.string().required('Zip is required.'),
  country: Yup.string().required('Country is required.'),
})

export const JoinSchema = (pp_id: any, isMerchItems: boolean) =>
  Yup.object().shape({
    charityId: Yup.number().positive().required(),
    priceTier: Yup.number().min(0),
    billing: pp_id === '1' ? Yup.object().unknown() : billing,
    sameAsBilling: Yup.boolean(),
    shipping:
      pp_id === '1'
        ? isMerchItems
          ? shipping
          : Yup.string().nullable()
        : shipping,
  })

export const JoinTeamSchema = (pp_id: any, isMerchItems: boolean) =>
  Yup.object().shape({
    joinCode: Yup.string().required(),
    priceTier: Yup.number().min(0),
    billing: pp_id === '1' ? Yup.object().unknown() : billing,
    sameAsBilling: Yup.boolean(),
    shipping:
      pp_id === '1'
        ? isMerchItems
          ? shipping
          : Yup.string().nullable()
        : shipping,
  })

export const getInitialAddress = (isBilling?: boolean) => ({
  [isBilling ? 'street_address' : 'street']: '',
  city: '',
  state: '',
  zip: '',
  country: '',
})
