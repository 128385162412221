import * as React from 'react'

import * as Yup from 'yup'
import { Formik, Form } from 'formik'

import { Link } from 'react-router-dom'

import { useLocation } from 'react-router'

import { useAuth } from '../../contexts/Authentication'

import Button from '../../components/Button'
import FormError from '../../components/FormError'
import InputField from '../../components/InputField'

const ResetPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .min(5, 'Password must be at least 5 characters')
    .required('Required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null as any], 'Passwords must match')
    .required('Required'),
})

const initialValues = {
  password: '',
  confirmPassword: '',
}

const ResetPasswordSet: React.FC = () => {
  const { search } = useLocation()
  const { resetPassword } = useAuth()

  const [isSuccess, setIsSuccess] = React.useState(false)

  const key = new URLSearchParams(search).get('key')
  const isKey = Boolean(key)

  const handleResetPassword = async (values: any, { setErrors }: any) => {
    if (!key) {
      return
    }

    try {
      await resetPassword({
        rKey: key,
        new_password: values.password?.trim?.(),
      })

      setIsSuccess(true)
    } catch (error) {
      const msg =
        error?.Message?.msg ||
        'Something went wrong with the request, Please try again'

      setErrors({
        form: msg,
      })
    }
  }

  if (!isKey) {
    return <p>404</p>
  }

  return (
    <div className="flex justify-center items-center pt-12">
      <div className="card">
        <h1 className="text-2xl uppercase leading-tight font-bold text-center mb-8 tracking-widest underlined-header">
          {isSuccess ? 'SUCCESS' : 'RESET PASSWORD'}
        </h1>
        {isSuccess ? (
          <div className="flex flex-col justify-center items-center">
            <p className="text-center my-4">
              Password reset successful. Please sign in with your new password
            </p>
            <Link
              to={`/login`}
              className="button w-16 sm:w-32 text-sm md:text-lg"
            >
              Sign In
            </Link>
          </div>
        ) : (
          <Formik
            initialValues={initialValues}
            validateOnChange
            validationSchema={ResetPasswordSchema}
            onSubmit={handleResetPassword}
          >
            {({ isValid, isSubmitting }) => (
              <Form>
                <FormError />
                <InputField
                  label="New Password"
                  type="password"
                  name="password"
                />
                <InputField
                  label="Confirm New Password"
                  type="password"
                  name="confirmPassword"
                />
                <div className="field-group mt-10">
                  <Button
                    type="submit"
                    isLoading={isSubmitting}
                    disabled={!isValid}
                    className={`button ${!isValid ? 'button-disabled' : ''}`}
                  >
                    RESET PASSWORD
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        )}
      </div>
    </div>
  )
}

export default ResetPasswordSet
