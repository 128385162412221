import React, { useEffect, useRef } from 'react'

import {
  Route,
  Switch,
  useParams,
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router-dom'

import { Form, Formik, FormikProps, useFormikContext } from 'formik'

import API, { URLS } from '../../services/api'

import { useAuth } from '../../contexts/Authentication'

import {
  Elements,
  useStripe,
  useElements,
  CardElement,
} from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'

import poweredBy from '../../assets/images/powered-by-stripe.png'

import Button from '../../components/Button'
import Checkbox from '../../components/Checkbox'
import FormError from '../../components/FormError'
import InputField from '../../components/InputField'
import SelectField from '../../components/SelectField'
import PriceTierSelect from '../../components/PriceTierSelect'

import { CountryOptions } from '../../utils/countryOptions'

import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete'

import {
  JoinSchema,
  JoinTeamSchema,
  getInitialAddress,
} from './JoinChallenge.utils'

const TERMS_AND_CONDITIONS_URL =
  'https://www.kilterrewards.com/terms-of-service'
const PRIVACY_POLICY = 'https://www.kilterrewards.com/privacy-policy'

const stripePromise = loadStripe(process.env.STRIPE_PUBLISHABLE_KEY as any)

const JoinChallenge = () => {
  const stripe = useStripe()
  const elements = useElements()

  const history = useHistory()
  const match = useRouteMatch<any>()

  const { id, type } = useParams<any>()
  const { state: authState } = useAuth()
  const { state, pathname } = useLocation<any>()

  const formikRef = useRef<FormikProps<any>>(null)

  const [isChecked, setIsChecked] = React.useState(true)
  const [challenge, setChallenge] = React.useState<any>()
  const [merchItem, setMerchItem] = React.useState<any[]>([])
  const [charities, setCharities] = React.useState<any[]>([])
  const [priceTiers, setPriceTiers] = React.useState<any[]>([])
  const [isStripeError, setIsStripeError] = React.useState(false)
  const [publicTeams, setPublicTeams] = React.useState<any[]>([])
  const [merchItemSizes, setMerchItemSizes] = React.useState<any[]>([])
  const [priceTierMerch, setPriceTierMerch] = React.useState<any[]>([])
  const [priceTierSelected, setPriceTierSelected] = React.useState(null)
  const [isLoadingChallenge, setIsLoadingChallenge] = React.useState(true)

  const pp_id = React.useMemo(
    () => String(challenge?.payment_processor_id),
    [challenge?.payment_processor_id]
  )

  const loadChallenge = async () => {
    setIsLoadingChallenge(true)
    const challengeFromState = (state && state.challenge) || {}

    if (challengeFromState.id === id && challengeFromState.type === type) {
      setChallenge(challengeFromState)
    } else {
      try {
        const endpoint = URLS.GET_EVENT_BY_ID
        const challengeDetails = await API.get(endpoint, {
          params: {
            challenge_id: id,
          },
        }).then(({ data }) => (data && data[0]) || data)

        setChallenge(challengeDetails)

        if (
          'payment_processor_id' in challengeDetails &&
          challengeDetails.payment_processor_id === 1
        ) {
          setIsChecked(false)
        }
      } catch (error) {}
    }

    setIsLoadingChallenge(false)
  }

  const loadPublicTeams = async () => {
    const teams = await API.get(URLS.GET_EVENT_TEAMS, {
      params: { challenge_id: id },
    }).then(({ data }) => data)
    if (teams.length && teams.length === 1) {
      setPublicTeams(teams)
    } else if (teams.length) {
      setPublicTeams([{ join_code: '', name: 'Select Team', id: '' }, ...teams])
    } else {
      setPublicTeams([])
    }
  }
  const loadCharities = async () => {
    const charities = await API.get(URLS.GET_CHARITIES).then(({ data }) => data)
    if (charities.length && charities.length === 1) {
      setCharities(charities)
    } else if (charities.length) {
      setCharities([{ id: '', name: 'Select Charity' }, ...charities])
    } else {
      setCharities([])
    }
  }
  const loadPriceTiers = async () => {
    const priceTiers = await API.get(URLS.GET_PRICE_TIERS, {
      params: { challenge_id: id },
    }).then(({ data }) => data)

    setPriceTiers(priceTiers)
  }

  const loadMerchandise = async () => {
    const priceTierMerch = await API.get(URLS.GET_EVENT_MERCH, {
      params: { challenge_id: id },
    }).then(({ data }) => data)

    setPriceTierMerch(priceTierMerch)
  }

  const loadMerchItems = (price: any) =>
    priceTierMerch.filter((tier) => tier.price == price)

  React.useEffect(() => {
    loadChallenge()
  }, [id, type])

  React.useEffect(() => {
    if (!challenge) {
      return
    }
    if (type === 'team') {
      loadPublicTeams()
    }
    if (type === 'individual') {
      loadCharities()
    }
    loadPriceTiers()
    loadMerchandise()
  }, [challenge])

  React.useEffect(() => {
    const merch = loadMerchItems(`${priceTierSelected}`)[0]?.merch_selection

    setMerchItem(merch)
    setMerchItemSizes(merch && merch.length ? merch[0]?.options : null)
  }, [priceTierSelected, priceTierMerch])

  const handleJGPayment = async (values: any, setErrors: Function) => {
    try {
      let merchResp: any = {}

      if (values?.shipping) {
        const { data } = await API.post<{
          error: boolean
          merch_order_id: number
        }>(`${URLS.SET_SHIPPING_INFO}`, {
          shipping_address: values.shipping,
          quantity: 1,
          merch_id: values.merchId ? parseInt(values.merchId, 10) : 1,
          email: authState.userInfo.email,
          phone: authState.userInfo.phone,
          full_name:
            authState.userInfo.first_name +
            ' ' +
            (authState.userInfo.last_name || ''),
          challenge_id: challenge.id,
          stripe_payment_id: '',
        })

        merchResp = data
      }

      if (merchResp?.error) {
        setErrors({ form: 'something went wrong, please try again' })

        return
      }

      const merch_order_id = merchResp?.merch_order_id

      const charity_id = values?.teamId
        ? publicTeams.find((t) => t.id == values?.teamId)?.charity_id
        : values?.charityId

      const { data: regResp } = await API.post<{
        error: boolean
        data: {
          billing_info: any
          challenge: any
          payment: any
          registration_id: number
          users_id: number
        }
        redirect_url: string
      }>(`${URLS.JS_REGISTER_EVENT}`, {
        merch_order_id,
        event_id: challenge.id,
        join_code: values.joinCode,
        charity_id: Number(charity_id),
        team_id: values?.teamId || undefined,
        price_tier: Number(values.priceTier),
        free_admission_code: !!values.coupon ? values.coupon : undefined,
      })

      if (regResp?.error) {
        setErrors({ form: String('something went wrong, please try again') })

        return
      }

      const isValidCoupon = Boolean(
        regResp?.redirect_url == '' && !!values?.coupon
      )

      const isNoPricetier = Boolean(
        regResp?.redirect_url === '' && values?.priceTier == 0
      )

      // if 'redirect_url' is empty than the coupon code is
      // considered to be valid and user can be directly joined to the event
      if (isValidCoupon || isNoPricetier) {
        const charity_id =
          type === 'individual'
            ? values?.charityId
            : values?.teamId
            ? publicTeams.find((t) => t.id == values?.teamId)?.charity_id
            : values?.charityId

        await API.post(`${URLS.V2_EVENT_JOIN}`, {
          team_id: values?.teamId || undefined,
          join_code: values.joinCode,
          challenge_id: Number(id),
          charity_id: Number(charity_id),
          free_admission_code: values?.coupon,
          price_tier: Number(values.priceTier),
        })

        history.push(`/challenge/${type}/${id}/success`)

        return
      }

      const { redirect_url } = regResp

      const search = new URLSearchParams(redirect_url)

      const exitUrl = search.get('exitUrl')

      if (!exitUrl) {
        return
      }

      const _exitUrl = new URL(exitUrl)

      const newExitUrl = `${window.location.origin}${_exitUrl.pathname}${_exitUrl.search}`

      search.set('exitUrl', encodeURIComponent(newExitUrl))

      const _redirect_url = decodeURIComponent(search.toString())

      window.open(_redirect_url, '_self')
    } catch (error: any) {
      const err = typeof error === 'string' ? error : error?.Message

      setErrors({ form: String(err) })
    }
  }

  const handleJoin = async (
    values: any,
    { setErrors }: any,
    fromStripe = false
  ): Promise<any> => {
    if (pp_id === '1') {
      await handleJGPayment(values, setErrors)

      return
    }

    if (values.coupon) {
      try {
        const { success, errorMessage } = await joinChallenge(values)
          .then(({ data }) => data)
          .catch((err) => {
            return { errorMessage: err.Message }
          })
        if (errorMessage) {
          setErrors({ form: errorMessage })
          return
        }
        if (success === true) {
          history.push(`/challenge/${type}/${id}/success`)
          return
        } else {
          setErrors({ coupon: 'Please enter a valid code.' })
        }
      } catch (e) {}
      return
    }
    const { client_secret, success, errorMessage } = await joinChallenge(values)
      .then(({ data }) => data)
      .catch((err) => {
        return { errorMessage: err.Message }
      })

    if (errorMessage) {
      setErrors({ form: errorMessage })
      return
    }

    if (success === true) {
      if (client_secret == '' && values?.merchId) {
        await API.post(URLS.SET_SHIPPING_INFO, {
          shipping_address: !values.sameAsBilling
            ? values.shipping
            : {
                street: values.billing.street_address,
                city: values.billing.city,
                state: values.billing.state,
                zip: values.billing.zip,
                country: values.billing.country,
              },
          quantity: 1,
          merch_id: values.merchId ? parseInt(values.merchId, 10) : 1,
          email: authState.userInfo.email,
          phone: authState.userInfo.phone,
          full_name:
            authState.userInfo.first_name +
            ' ' +
            (authState.userInfo.last_name || ''),
          challenge_id: challenge.id,
          stripe_payment_id: '',
        })
      }

      history.push(`/challenge/${type}/${id}/success${location.search}`)
      return
    }
    if (client_secret) {
      if (fromStripe && (fromStripe as any) <= 5) {
        // sleep for 500 seconds
        await new Promise((res) => setTimeout(res, 1000))

        return handleJoin(values, { setErrors }, (fromStripe as any) + 1)
      } else if (fromStripe) {
        setErrors({ form: 'Something went wrong try again later.' })

        return
      }

      const stripeResult: any = await payWithStripe(client_secret)
      if (stripeResult.ok) {
        await API.post(URLS.SET_BILLING_INFO, {
          payment_intent_id: client_secret.split('_secret')[0],
          ...values.billing,
          full_name:
            authState.userInfo.first_name +
            ' ' +
            (authState.userInfo.last_name || ''),
        })

        await API.post(URLS.SET_SHIPPING_INFO, {
          shipping_address: !values.sameAsBilling
            ? values.shipping
            : {
                street: values.billing.street_address,
                city: values.billing.city,
                state: values.billing.state,
                zip: values.billing.zip,
                country: values.billing.country,
              },
          quantity: 1,
          merch_id: values.merchId ? parseInt(values.merchId, 10) : 1,
          email: authState.userInfo.email,
          phone: authState.userInfo.phone,
          full_name:
            authState.userInfo.first_name +
            ' ' +
            (authState.userInfo.last_name || ''),
          challenge_id: challenge.id,
          stripe_payment_id: client_secret.split('_secret')[0], // Not sure where I can get this
        })

        return handleJoin(values, { setErrors }, 1 as any)
      }

      setErrors({ form: stripeResult.error })
    }
  }

  const joinChallenge = (values: any) => {
    const endpoint = URLS.JOIN_EVENT

    const charity_id = values?.teamId
      ? publicTeams.find((t) => t.id == values?.teamId)?.charity_id
      : values?.charityId

    return API.post(
      endpoint,
      type === 'individual'
        ? {
            challenge_id: Number(id),
            charity_id: Number(values.charityId),
            price_tier: Number(values.priceTier),
            free_admission_code: !!values.coupon ? values.coupon : undefined,
            tip: Number(values?.tip),
          }
        : {
            challenge_id: Number(id),
            charity_id: Number(charity_id),
            price_tier: Number(values.priceTier),
            join_code: values.joinCode,
            team_id: values?.teamId || undefined,
            free_admission_code: !!values.coupon ? values.coupon : undefined,
            tip: Number(values?.tip),
          }
    )
  }

  const payWithStripe = async (paymentIntent: any) => {
    const card: any = elements?.getElement(CardElement)
    const result = await stripe?.confirmCardPayment(paymentIntent, {
      payment_method: {
        card,
        billing_details: {
          name:
            authState.userInfo.first_name +
            ' ' +
            (authState.userInfo.last_name || ''),
          email: authState.userInfo.email,
        },
      },
    })
    if (result?.error) {
      return { error: result.error.message }
    } else if (
      result?.paymentIntent &&
      result?.paymentIntent.status === 'succeeded'
    ) {
      return { ok: 'success' }
    }
  }
  const chartiesOptions = React.useMemo(
    () =>
      charities.map((p) => ({
        label: p.name,
        value: p.id,
      })),
    [charities]
  )
  const priceTiersOptions = React.useMemo(
    () =>
      priceTiers.map((p) => ({
        label: p.label,
        value: p.price,
      })),
    [priceTiers]
  )
  const publicTeamsOptions = React.useMemo(
    () =>
      publicTeams.map((p) => ({
        label: p.name,
        value: p.join_code,
        teamId: p.id,
      })),
    [publicTeams]
  )

  const merchItemOptions = React.useMemo(
    () =>
      merchItem?.map((p) => ({
        label: p.label,
        value: p.label,
      })),
    [merchItem]
  )

  const merchItemSizesOptions = React.useMemo(
    () =>
      merchItemSizes?.map((p) => ({
        label: `${p.label} - ${p.size}`,
        value: p.id,
      })),
    [merchItemOptions]
  )

  const handlePriceTierChange = (e: any) => {
    setPriceTierSelected(e.target.value)
    setIsStripeError(Boolean(e.target.value))
  }

  useEffect(() => {
    if (priceTiersOptions && priceTiersOptions.length) {
      const firstOption = priceTiersOptions[0].value

      setPriceTierSelected(firstOption)
      setIsStripeError(Boolean(firstOption))

      formikRef.current?.setFieldValue('priceTier', firstOption)
    }
  }, [priceTiersOptions])

  useEffect(() => {
    if (window.performance) {
      if (window.performance?.navigation?.type == 1) {
        if (pathname.includes('/stripe')) {
          history.goBack()
        }
      }
    }
  }, [])

  const isMerchItems =
    !!merchItemOptions?.length || merchItemSizesOptions?.length

  return !isLoadingChallenge && challenge ? (
    <Formik
      initialValues={{
        joinCode: formikRef.current?.values?.joinCode || undefined,
        teamId: formikRef.current?.values?.teamId || undefined,
        priceTier: priceTierSelected ?? undefined,
        charityId: formikRef.current?.values?.charityId || undefined,
        sameAsBilling: pp_id === '1' ? false : true,
        shipping:
          pp_id === '1'
            ? isMerchItems
              ? getInitialAddress()
              : undefined
            : getInitialAddress(),
        billing: pp_id === '1' ? undefined : getInitialAddress(true),
        coupon: challenge?.paid ? '' : undefined,
        tip: formikRef.current?.values?.tip || 0,
      }}
      validateOnChange
      innerRef={formikRef}
      onSubmit={handleJoin}
      validationSchema={
        type === 'team'
          ? JoinTeamSchema(pp_id, Boolean(isMerchItems))
          : JoinSchema(pp_id, Boolean(isMerchItems))
      }
    >
      {({
        values,
        errors,
        isValid,
        touched,
        isSubmitting,
        setTouched,
        setFieldValue,
      }) => {
        const isNextDisabled =
          type === 'team' &&
          (values.joinCode === undefined || values?.joinCode == '')

        const isJoinDisabled =
          !isValid ||
          (pp_id === '1' ? false : values?.coupon ? false : isStripeError)

        return (
          <Form>
            <div className="flex justify-center items-center pt-12">
              <div className="card shadow-none max-w-2xl pb-10 sm:p-6 mb-12">
                <div className="mb-4">
                  <img
                    src={
                      challenge.logo || 'https://placehold.it/200x200?text=logo'
                    }
                    style={{
                      height: '285px',
                      maxWidth: '569px',
                      width: '100%',
                      background: '#f1f1f1',
                      objectFit: 'contain',
                    }}
                    className="m-auto mb-2"
                  />
                  <h1 className="text-2xl uppercase leading-tight font-bold text-center my-4">
                    {challenge.name}
                  </h1>
                </div>
                <>
                  <FormError />
                  <Switch>
                    <Route exact path={match.path}>
                      <>
                        <h5 className="text-xl mb-2">
                          CONTINUE WITH REGISTRATION
                        </h5>
                        {(!publicTeamsOptions || !publicTeamsOptions.length) &&
                        type === 'team' ? (
                          <InputField label="Team Code" name="joinCode" />
                        ) : null}
                        {!!chartiesOptions && chartiesOptions.length ? (
                          <SelectField
                            label="SELECT CHARITY"
                            name="charityId"
                            options={chartiesOptions}
                          />
                        ) : null}
                        {!!publicTeamsOptions && publicTeamsOptions.length ? (
                          <SelectField
                            label="SELECT TEAM"
                            name="joinCode"
                            hidden={publicTeams.length === 1}
                            options={publicTeamsOptions}
                            hint={`If you do not have a team and are joining this event as an individual supporter, please select the very first option under the Team dropdown.`}
                          />
                        ) : null}
                        {!!priceTiersOptions && priceTiersOptions.length ? (
                          <PriceTierSelect
                            options={priceTiersOptions}
                            selected={priceTierSelected}
                            handleChange={handlePriceTierChange}
                          />
                        ) : null}
                        {isMerchItems ? (
                          <h5 className="text-xl mt-3 mb-2">ITEM</h5>
                        ) : null}
                        {!!merchItemOptions && merchItemOptions.length ? (
                          <SelectField
                            label="SELECT MERCHANDISE"
                            name="merchType"
                            options={merchItemOptions}
                          />
                        ) : null}
                        {!!merchItemSizesOptions &&
                        merchItemSizesOptions.length ? (
                          <SelectField
                            label="SELECT SIZE"
                            name="merchId"
                            options={merchItemSizesOptions}
                          />
                        ) : null}
                        {pp_id === '1' && Boolean(isMerchItems) && (
                          <>
                            <h5 className="text-xl mt-3 my-3">
                              Shipping Address
                            </h5>
                            <PlacesAutocomplete
                              value={values?.shipping?.street || ''}
                              onChange={(text) =>
                                setFieldValue('shipping.street', text)
                              }
                              searchOptions={{
                                types: ['address'],
                              }}
                              debounce={400}
                              onSelect={async (address) => {
                                try {
                                  const result = await geocodeByAddress(address)
                                  const componentForm: Record<string, string> =
                                    {
                                      street_number: 'short_name',
                                      route: 'long_name',
                                      locality: 'long_name',
                                      administrative_area_level_1: 'short_name',
                                      administrative_area_level_2: 'long_name',
                                      country: 'short_name',
                                      postal_code: 'short_name',
                                    }
                                  const shippingObj: Record<string, string> = {}
                                  if (result && result[0]) {
                                    result[0].address_components.forEach(
                                      (comp: any) => {
                                        const addressType = comp.types[0]
                                        if (componentForm[addressType]) {
                                          const val =
                                            comp[componentForm[addressType]]

                                          shippingObj[addressType] = val
                                        }
                                      }
                                    )

                                    setFieldValue('shipping', {
                                      street: `${
                                        shippingObj.street_number || ''
                                      }${
                                        shippingObj.street_number &&
                                        shippingObj.route
                                          ? ' '
                                          : ''
                                      }${shippingObj.route || ''}`,
                                      city:
                                        shippingObj.locality ||
                                        shippingObj.administrative_area_level_2 ||
                                        '',
                                      state:
                                        shippingObj.administrative_area_level_1 ||
                                        '',
                                      zip: shippingObj.postal_code || '',
                                      country: shippingObj.country || '',
                                    })
                                  }
                                } catch (e) {
                                  console.log('handleSelectShipping -> e', e)
                                }
                              }}
                            >
                              {({
                                getInputProps,
                                suggestions,
                                getSuggestionItemProps,
                                loading,
                              }) => (
                                <div className="places-field-group">
                                  <div className="field-group">
                                    <label className="field-label">
                                      Street
                                    </label>
                                    <input
                                      className="field"
                                      {...getInputProps({
                                        placeholder: 'Search Address',
                                        onBlur: () => {
                                          setTouched({
                                            ...touched,
                                            shipping: {
                                              ...(touched.shipping as any),
                                              street: true,
                                            },
                                          })
                                        },
                                        style: { borderWidth: '2px' },
                                      })}
                                    />
                                    {(errors.shipping as any)?.street &&
                                    (touched.shipping as any)?.street ? (
                                      <div className="field-error">
                                        {(errors.shipping as any)?.street}
                                      </div>
                                    ) : null}
                                  </div>
                                  <div className="autocomplete-dropdown-container">
                                    {loading && <div>Loading...</div>}
                                    {suggestions.map((suggestion, index) => {
                                      const className = suggestion.active
                                        ? 'px-5 py-5 w-full'
                                        : 'px-5 py-5 w-full'
                                      // inline style for demonstration purpose
                                      const style = suggestion.active
                                        ? {
                                            backgroundColor: '#fafafa',
                                            cursor: 'pointer',
                                          }
                                        : {
                                            backgroundColor: '#ffffff',
                                            cursor: 'pointer',
                                          }
                                      return (
                                        <div
                                          {...getSuggestionItemProps(
                                            suggestion,
                                            {
                                              className,
                                              style,
                                            }
                                          )}
                                          key={`${suggestion?.id}-${index}`}
                                        >
                                          <span>{suggestion.description}</span>
                                        </div>
                                      )
                                    })}
                                  </div>
                                </div>
                              )}
                            </PlacesAutocomplete>
                            <InputField
                              label="City"
                              type="text"
                              name="shipping.city"
                            />
                            <InputField
                              label="State"
                              type="text"
                              name="shipping.state"
                            />
                            <InputField
                              label="Zip"
                              type="text"
                              name="shipping.zip"
                            />
                            <SelectField
                              label="Country"
                              type="text"
                              name="shipping.country"
                              options={CountryOptions}
                            />
                          </>
                        )}
                        <div className="h-5" />
                        {challenge.paid && pp_id === '1' ? (
                          <InputField
                            label="Coupon Code"
                            name="coupon"
                            hint="Have a code? Enter it here and click Join!"
                          />
                        ) : null}
                        <TipAndTotal
                          pp_id={pp_id}
                          priceTier={priceTierSelected || 0}
                          isChecked={isChecked}
                          setIsChecked={setIsChecked}
                        />
                        <div className="field-group">
                          <Button
                            type={pp_id === '1' ? 'submit' : undefined}
                            isLoading={isSubmitting}
                            onClick={
                              pp_id === '1'
                                ? undefined
                                : () => {
                                    history.push({
                                      pathname:
                                        match.url +
                                        `/${pp_id === '1' ? 'jg' : 'stripe'}`,
                                      state: {},
                                    })
                                  }
                            }
                            disabled={isNextDisabled}
                            className={`button ${
                              isNextDisabled ? 'button-disabled' : ''
                            }`}
                          >
                            {pp_id === '1' ? 'Join Now' : 'Next'}
                          </Button>
                        </div>
                        {pp_id === '1' && (
                          <div className="flex flex-col justify-center items-center">
                            <div style={{ maxWidth: '450px' }}>
                              <div className="text-center">
                                <svg
                                  className="mb-2 opacity-50 inline-block"
                                  width="18"
                                  height="18"
                                  viewBox="0 0 17.406 17.407"
                                >
                                  <g id="information">
                                    <path d="M8.7,0a8.7,8.7,0,1,0,8.7,8.7A8.713,8.713,0,0,0,8.7,0Zm0,15.824A7.121,7.121,0,1,1,15.824,8.7,7.129,7.129,0,0,1,8.7,15.824Z" />
                                    <path
                                      d="M146.057,70a1.055,1.055,0,1,0,1.055,1.055A1.056,1.056,0,0,0,146.057,70Z"
                                      transform="translate(-137.354 -66.308)"
                                    />
                                    <path
                                      d="M150.791,140a.791.791,0,0,0-.791.791v4.747a.791.791,0,0,0,1.582,0v-4.747A.791.791,0,0,0,150.791,140Z"
                                      transform="translate(-142.088 -132.615)"
                                    />
                                  </g>
                                </svg>
                              </div>
                              <div className="opacity-50 text-center mb-5">
                                You will be redirected to our payment processor
                                JustGiving.
                              </div>
                              <div className="opacity-50 text-center">
                                All payments are processed in USD. Donations are
                                tax deductible based on an acknowledgement
                                letter from the charity.
                              </div>
                              <div className="terms-and-conditions opacity-50 text-center">
                                By continuing, you acknowledge Kilter's{' '}
                                <a
                                  className="href-link"
                                  href={TERMS_AND_CONDITIONS_URL}
                                  target="_blank"
                                >
                                  Terms of Service
                                </a>{' '}
                                &{' '}
                                <a
                                  className="href-link"
                                  href={PRIVACY_POLICY}
                                  target="_blank"
                                >
                                  Privacy Policy
                                </a>
                                . This link and donation page is publicly
                                accessible.
                              </div>
                            </div>
                            <img
                              className="powered-by-stripe"
                              src={require('../../assets/images/JGLogo.svg')}
                              style={{ maxHeight: '30px' }}
                            />
                          </div>
                        )}
                      </>
                    </Route>
                    {pp_id !== '1' && (
                      <Route
                        path={`${match.path}/${
                          pp_id === '1' ? 'jg' : 'stripe'
                        }`}
                        exact
                      >
                        <h5 className="text-xl my-3 font-semibold">
                          Billing Address
                        </h5>
                        <PlacesAutocomplete
                          value={(values.billing as any).street_address || ''}
                          onChange={(text) =>
                            setFieldValue('billing.street_address', text)
                          }
                          searchOptions={{
                            types: ['address'],
                          }}
                          debounce={400}
                          onSelect={async (address) => {
                            try {
                              const result = await geocodeByAddress(address)
                              const componentForm: Record<string, string> = {
                                street_number: 'short_name',
                                route: 'long_name',
                                locality: 'long_name',
                                administrative_area_level_1: 'short_name',
                                administrative_area_level_2: 'long_name',
                                country: 'short_name',
                                postal_code: 'short_name',
                              }
                              const billingObj: Record<string, string> = {}
                              if (result && result[0]) {
                                result[0].address_components.forEach(
                                  (comp: any) => {
                                    const addressType = comp.types[0]
                                    if (componentForm[addressType]) {
                                      const val =
                                        comp[componentForm[addressType]]
                                      billingObj[addressType] = val
                                    }
                                  }
                                )
                                setFieldValue('billing', {
                                  street_address: `${
                                    billingObj.street_number || ''
                                  }${
                                    billingObj.street_number && billingObj.route
                                      ? ' '
                                      : ''
                                  }${billingObj.route || ''}`,
                                  city:
                                    billingObj.locality ||
                                    billingObj.administrative_area_level_2 ||
                                    '',
                                  state:
                                    billingObj.administrative_area_level_1 ||
                                    '',
                                  zip: billingObj.postal_code || '',
                                  country: billingObj.country || '',
                                })
                              }
                            } catch (e) {}
                          }}
                        >
                          {({
                            getInputProps,
                            suggestions,
                            getSuggestionItemProps,
                            loading,
                          }) => (
                            <div className="places-field-group">
                              <div className="field-group">
                                <label className="field-label">Street</label>
                                <input
                                  className="field"
                                  {...getInputProps({
                                    placeholder: 'Search Address',
                                    onBlur: () => {
                                      setTouched({
                                        ...touched,
                                        billing: {
                                          ...(touched.billing as any),
                                          street_address: true,
                                        },
                                      })
                                    },
                                    style: { borderWidth: '2px' },
                                  })}
                                />
                                {(errors.billing as any)?.street_address &&
                                (touched.billing as any)?.street_address ? (
                                  <div className="field-error">
                                    {(errors.billing as any)?.street_address}
                                  </div>
                                ) : null}
                              </div>
                              <div className="autocomplete-dropdown-container">
                                {loading && <div>Loading...</div>}
                                {suggestions.map((suggestion, index) => {
                                  const className = suggestion.active
                                    ? 'px-5 py-5 w-full'
                                    : 'px-5 py-5 w-full'
                                  // inline style for demonstration purpose
                                  const style = suggestion.active
                                    ? {
                                        backgroundColor: '#fafafa',
                                        cursor: 'pointer',
                                      }
                                    : {
                                        backgroundColor: '#ffffff',
                                        cursor: 'pointer',
                                      }
                                  return (
                                    <div
                                      {...getSuggestionItemProps(suggestion, {
                                        className,
                                        style,
                                      })}
                                      key={`${suggestion?.id}-${index}`}
                                    >
                                      <span>{suggestion.description}</span>
                                    </div>
                                  )
                                })}
                              </div>
                            </div>
                          )}
                        </PlacesAutocomplete>
                        <InputField
                          label="City"
                          type="text"
                          name="billing.city"
                        />
                        <InputField
                          label="State"
                          type="text"
                          name="billing.state"
                        />
                        <InputField
                          label="Zip"
                          type="text"
                          name="billing.zip"
                        />
                        <SelectField
                          label="Country"
                          type="text"
                          name="billing.country"
                          options={CountryOptions}
                        />
                        <Checkbox
                          name="sameAsBilling"
                          label="Shipping Address Same as Billing Address"
                        />
                        {!values.sameAsBilling && (
                          <>
                            <h5 className="text-xl my-3 font-semibold">
                              Shipping Address
                            </h5>
                            <PlacesAutocomplete
                              value={values.shipping.street || ''}
                              onChange={(text) =>
                                setFieldValue('shipping.street', text)
                              }
                              searchOptions={{
                                types: ['address'],
                              }}
                              debounce={400}
                              onSelect={async (address) => {
                                try {
                                  const result = await geocodeByAddress(address)
                                  const componentForm: Record<string, string> =
                                    {
                                      street_number: 'short_name',
                                      route: 'long_name',
                                      locality: 'long_name',
                                      administrative_area_level_1: 'short_name',
                                      administrative_area_level_2: 'long_name',
                                      country: 'short_name',
                                      postal_code: 'short_name',
                                    }
                                  const shippingObj: Record<string, string> = {}
                                  if (result && result[0]) {
                                    result[0].address_components.forEach(
                                      (comp: any) => {
                                        const addressType = comp.types[0]
                                        if (componentForm[addressType]) {
                                          const val =
                                            comp[componentForm[addressType]]

                                          shippingObj[addressType] = val
                                        }
                                      }
                                    )

                                    setFieldValue('shipping', {
                                      street: `${
                                        shippingObj.street_number || ''
                                      }${
                                        shippingObj.street_number &&
                                        shippingObj.route
                                          ? ' '
                                          : ''
                                      }${shippingObj.route || ''}`,
                                      city:
                                        shippingObj.locality ||
                                        shippingObj.administrative_area_level_2 ||
                                        '',
                                      state:
                                        shippingObj.administrative_area_level_1 ||
                                        '',
                                      zip: shippingObj.postal_code || '',
                                      country: shippingObj.country || '',
                                    })
                                  }
                                } catch (e) {
                                  console.log('handleSelectShipping -> e', e)
                                }
                              }}
                            >
                              {({
                                getInputProps,
                                suggestions,
                                getSuggestionItemProps,
                                loading,
                              }) => (
                                <div className="places-field-group">
                                  <div className="field-group">
                                    <label className="field-label">
                                      Street
                                    </label>
                                    <input
                                      className="field"
                                      {...getInputProps({
                                        placeholder: 'Search Address',
                                        onBlur: () => {
                                          setTouched({
                                            ...touched,
                                            shipping: {
                                              ...(touched.shipping as any),
                                              street: true,
                                            },
                                          })
                                        },
                                        style: { borderWidth: '2px' },
                                      })}
                                    />
                                    {(errors.shipping as any)?.street &&
                                    (touched.shipping as any)?.street ? (
                                      <div className="field-error">
                                        {(errors.shipping as any)?.street}
                                      </div>
                                    ) : null}
                                  </div>
                                  <div className="autocomplete-dropdown-container">
                                    {loading && <div>Loading...</div>}
                                    {suggestions.map((suggestion, index) => {
                                      const className = suggestion.active
                                        ? 'px-5 py-5 w-full'
                                        : 'px-5 py-5 w-full'
                                      // inline style for demonstration purpose
                                      const style = suggestion.active
                                        ? {
                                            backgroundColor: '#fafafa',
                                            cursor: 'pointer',
                                          }
                                        : {
                                            backgroundColor: '#ffffff',
                                            cursor: 'pointer',
                                          }
                                      return (
                                        <div
                                          {...getSuggestionItemProps(
                                            suggestion,
                                            {
                                              className,
                                              style,
                                            }
                                          )}
                                          key={`${suggestion?.id}-${index}`}
                                        >
                                          <span>{suggestion.description}</span>
                                        </div>
                                      )
                                    })}
                                  </div>
                                </div>
                              )}
                            </PlacesAutocomplete>
                            <InputField
                              label="City"
                              type="text"
                              name="shipping.city"
                            />
                            <InputField
                              label="State"
                              type="text"
                              name="shipping.state"
                            />
                            <InputField
                              label="Zip"
                              type="text"
                              name="shipping.zip"
                            />
                            <SelectField
                              label="Country"
                              type="text"
                              name="shipping.country"
                              options={CountryOptions}
                            />
                          </>
                        )}
                        {pp_id === '2' && (
                          <h5 className="text-xl mt-8 mb-3 font-semibold">
                            PAYMENT INFO
                          </h5>
                        )}
                        {challenge.paid ? (
                          <InputField
                            label="Coupon Code"
                            name="coupon"
                            hint="Have a code? Enter it here and click Join!"
                          />
                        ) : null}
                        {pp_id === '1' ||
                        String(values?.coupon || '')?.trim()?.length >
                          0 ? null : (
                          <StripeField
                            label="Card Details"
                            selectedTier={priceTierSelected}
                            setIsStripeError={setIsStripeError}
                          />
                        )}
                        <TipAndTotal
                          pp_id={pp_id}
                          priceTier={priceTierSelected || 0}
                          isChecked={isChecked}
                          setIsChecked={setIsChecked}
                        />
                        <div className="field-group my-4">
                          <Button
                            type="submit"
                            isLoading={isSubmitting}
                            disabled={isJoinDisabled}
                            className={`button ${
                              isJoinDisabled ? 'button-disabled' : ''
                            }`}
                          >
                            Join Now
                          </Button>
                        </div>
                        <div className="flex flex-col justify-center items-center">
                          <div style={{ maxWidth: '450px' }}>
                            <div className="text-center">
                              <svg
                                className="mb-2 opacity-50 inline-block"
                                width="18"
                                height="18"
                                viewBox="0 0 17.406 17.407"
                              >
                                <g id="information">
                                  <path d="M8.7,0a8.7,8.7,0,1,0,8.7,8.7A8.713,8.713,0,0,0,8.7,0Zm0,15.824A7.121,7.121,0,1,1,15.824,8.7,7.129,7.129,0,0,1,8.7,15.824Z" />
                                  <path
                                    d="M146.057,70a1.055,1.055,0,1,0,1.055,1.055A1.056,1.056,0,0,0,146.057,70Z"
                                    transform="translate(-137.354 -66.308)"
                                  />
                                  <path
                                    d="M150.791,140a.791.791,0,0,0-.791.791v4.747a.791.791,0,0,0,1.582,0v-4.747A.791.791,0,0,0,150.791,140Z"
                                    transform="translate(-142.088 -132.615)"
                                  />
                                </g>
                              </svg>
                            </div>
                            <div className="opacity-50 text-center">
                              All payments are processed in USD. Donations are
                              tax deductible based on an acknowledgement letter
                              from the charity.
                            </div>
                            <div className="terms-and-conditions opacity-50 text-center">
                              By continuing, you acknowledge Kilter's{' '}
                              <a
                                className="href-link"
                                href={TERMS_AND_CONDITIONS_URL}
                                target="_blank"
                              >
                                Terms of Service
                              </a>{' '}
                              &{' '}
                              <a
                                className="href-link"
                                href={PRIVACY_POLICY}
                                target="_blank"
                              >
                                Privacy Policy
                              </a>
                              . This link and donation page is publicly
                              accessible.
                            </div>
                          </div>
                          <img className="powered-by-stripe" src={poweredBy} />
                        </div>
                      </Route>
                    )}
                  </Switch>
                </>
              </div>
            </div>
          </Form>
        )
      }}
    </Formik>
  ) : null
}

const StripeField = ({ label, selectedTier, setIsStripeError }: any) => {
  const elements = useElements()
  const formikContext = useFormikContext<any>()

  const card = elements?.getElement(CardElement)
  const isSelectedTier = selectedTier || formikContext?.values?.priceTier > 0

  useEffect(() => {
    if (!card || !isSelectedTier) {
      return
    }

    const unsubscribe = card.on('change', (e) => {
      if (e.empty) {
        setIsStripeError?.(true)
      } else if (e.error) {
        setIsStripeError?.(true)
      } else if (!e.complete) {
        setIsStripeError?.(true)
      } else if (e.complete) {
        setIsStripeError?.(false)
      }
    })

    return () => {
      const isDestroyed = !elements?.getElement(CardElement)

      if (!isDestroyed) {
        unsubscribe.clear()
      }
    }
  }, [card, isSelectedTier])

  return isSelectedTier ? (
    <div className="field-group mt-6">
      <div className="field-label">{label}</div>
      <div className="field">
        <CardElement
          options={{
            style: {
              base: {
                fontSize: '16px',
                color: '#424770',
                '::placeholder': {
                  color: '#aab7c4',
                },
              },
              invalid: {
                color: '#9e2146',
              },
            },
          }}
        />
      </div>
    </div>
  ) : null
}

export default (props: any) => {
  return (
    <Elements stripe={stripePromise}>
      <JoinChallenge {...props} />
    </Elements>
  )
}

const TipAndTotal = ({
  priceTier,
  isChecked,
  setIsChecked,
  pp_id,
}: {
  priceTier: number
  isChecked: boolean
  setIsChecked: Function
  pp_id: string
}) => {
  const { setFieldValue } = useFormikContext<any>()

  const getTip = (amount: number) => {
    if (amount > 100) {
      return 5
    } else if (amount <= 5) {
      return 20
    } else if (amount < 10.01) {
      return 15
    } else if (amount < 30) {
      return 10
    } else if (amount <= 100) {
      return 10
    } else {
      return 0
    }
  }

  const fee = Number(priceTier) * (Number(getTip(priceTier)) / 100)
  const total = !isChecked ? Number(priceTier) : Number(priceTier) + fee

  return (
    <div>
      {pp_id === '2' && (
        <>
          {!!priceTier && (
            <label className="inline-flex items-center mt-3">
              <input
                type="checkbox"
                defaultChecked={isChecked}
                className="form-checkbox h-5 w-5 text-gray-600"
                onChange={({ target }) => {
                  setIsChecked(target.checked)
                  setFieldValue(
                    'tip',
                    target.checked
                      ? Number(priceTier) * Number(getTip(priceTier) / 100)
                      : 0
                  )
                }}
              />
              <span className="ml-2 text-gray-700">
                I want to cover the fees of $
                {(fee || 0)?.toFixed(2)?.toLocaleString()} with my registration.
              </span>
            </label>
          )}
        </>
      )}
      <p className="text-center my-4 font-bold opacity-50">
        Total: $
        {Number(total || 0)
          ?.toFixed(2)
          ?.toLocaleString()}
      </p>
    </div>
  )
}
