import * as React from 'react'
import { useField, useFormikContext } from 'formik'

const SelectField = ({ label, options, hint, handleChange, ...props }: any) => {
  const formik = useFormikContext<any>()
  const [field, meta, helpers] = useField(props)

  React.useEffect(() => {
    if (!options || !options.length) {
      return
    }

    helpers.setValue(options[0].value)

    if ('teamId' in options[0] && options[0].teamId) {
      formik.setFieldValue('teamId', options[0].teamId)
    }
  }, [options])

  const filteredOptions = React.useMemo(
    () =>
      Boolean(field.value)
        ? options?.filter?.((e: any) => !!e?.value)
        : options,
    [field.value]
  )

  const isTeamsOption = filteredOptions.some((i: any) => 'teamId' in i)

  return (
    <div className={`field-group ${props.hidden ? 'hidden' : ''}`}>
      <label className="field-label">{label}</label>
      <div
        className="inline-block relative w-full"
        onClick={() => helpers.setTouched(true)}
      >
        <select
          className="block appearance-none w-full field"
          {...props}
          {...field}
          value={isTeamsOption ? formik.values?.teamId : field.value}
          style={{ borderWidth: '2px' }}
          onChange={(e) => {
            typeof handleChange === 'function' && handleChange(e)

            if (isTeamsOption) {
              const teamId = parseInt(e.target.value)

              const joinCode = filteredOptions.find(
                (i: any) => i.teamId == teamId
              )?.value

              formik.setFieldValue('teamId', teamId)
              helpers.setValue(joinCode)

              return
            }

            helpers.setValue(e.target.value)
          }}
        >
          {(filteredOptions || []).map(
            ({ value, label: optionLabel, teamId = null }: any) => (
              <option
                value={Boolean(teamId) ? teamId : value}
                key={`${Boolean(teamId) ? teamId : value}-${optionLabel}`}
              >
                {optionLabel}
              </option>
            )
          )}
        </select>
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
          <svg
            className="fill-current h-4 w-4"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"></path>
          </svg>
        </div>
      </div>
      {hint ? (
        <div className="field-hint whitespace-pre-line">{hint}</div>
      ) : null}
      {meta.touched && meta.error ? (
        <div className="field-error">{meta.error}</div>
      ) : null}
    </div>
  )
}

export default SelectField
