import * as React from 'react'

import * as Yup from 'yup'
import { Formik, Form } from 'formik'

import { useAuth } from '../../contexts/Authentication'

import Button from '../../components/Button'
import FormError from '../../components/FormError'
import InputField from '../../components/InputField'

interface ResetPasswordFormValues {
  email: string
}

const ResetPasswordSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Required'),
})

const ResetPassword = () => {
  const { requestResetPassword } = useAuth()

  const [isLoading, setIsLoading] = React.useState(false)
  const [isSuccess, setIsSuccess] = React.useState(false)

  const handleResetPassword = React.useCallback(
    async (values: ResetPasswordFormValues, { setErrors }: any) => {
      setIsLoading(true)

      const status = await requestResetPassword({
        email: values?.email?.trim(),
      })

      if (status) {
        setIsSuccess(true)
      } else {
        setErrors({
          form: 'Unable to locate account associated with that email.',
        })
      }

      setIsLoading(false)
    },
    []
  )

  return (
    <div className="flex justify-center items-center pt-12">
      <div className="card">
        <h1 className="text-2xl uppercase leading-tight font-bold text-center mb-8 tracking-widest underlined-header">
          {isSuccess ? 'Thank You' : 'FORGOT YOUR PASSWORD?'}
        </h1>
        <div className="mt-1 text-center mb-8 pt-10">
          {isSuccess
            ? 'Request password link sent. Check your email to reset your password.'
            : 'Type the email address you used to create your account and we’ll send a password reset link to that email address.'}
        </div>
        {isSuccess ? null : (
          <Formik
            initialValues={{ email: '', password: '' }}
            validationSchema={ResetPasswordSchema}
            onSubmit={handleResetPassword}
          >
            <Form>
              <FormError />
              <InputField label="Email" type="text" name="email" />
              <div className="field-group mt-10">
                <Button type="submit" isLoading={isLoading}>
                  Reset Password
                </Button>
              </div>
            </Form>
          </Formik>
        )}
      </div>
    </div>
  )
}

export default ResetPassword
