import * as React from 'react'

import { useParams } from 'react-router-dom'

import { isMobile } from 'react-device-detect'

import axios, { AxiosRequestConfig } from 'axios'

import api, { URLS } from '../../services/api'

import { useAuth } from '../../contexts/Authentication'

import appstore from '../../assets/images/appstore.png'
import playstore from '../../assets/images/playstore.png'
import checkmark from '../../assets/images/check_mark.png'
import mobilescreens from '../../assets/images/mobile_image.png'

import { Participation } from '../../types/event'

import './styles.css'

type Params = {
  id: string
  type: string
}

const KR_EVENTS_PAGE = `https://www.kilterrewards.com/events`

const ChallengeSuccess = () => {
  const { state } = useAuth()
  const { id } = useParams<Params>()

  const [link, setLink] = React.useState<string | null>(null)
  const [isLoading, setIsLoading] = React.useState<boolean>(true)

  React.useEffect(() => {
    const getEventParticipation = async () => {
      try {
        const { data } = await api.get<Participation[]>(
          `${URLS.GET_PARTICIPATIONS}`
        )

        const participation = data.find(
          ({ challenge }) => challenge.id === parseInt(id)
        )

        return participation ?? null
      } catch (error) {
        return null
      }
    }

    const generateDeepLink = async () => {
      try {
        const participation = await getEventParticipation()

        const branchUrl = `https://api2.branch.io/v1/url`
        const payload = {
          branch_key: `${process.env.BRANCH_KEY}`,
          data: {
            $og_title: participation?.challenge?.name ?? '',
            $og_description: participation?.challenge?.description ?? '',
            $og_image_url: participation?.challenge?.logo ?? '',
            participation: participation ?? { id },
            user: {
              key: state?.auth?.key,
              firstName: state?.userInfo?.first_name,
            },
          },
        }
        const config: AxiosRequestConfig = {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        }

        const { data } = await axios.post<{ url: string }>(
          branchUrl,
          payload,
          config
        )

        if (data && 'url' in data) {
          setLink(data.url)
        }

        setIsLoading(false)
      } catch (error) {
        console.log(error)
        setIsLoading(false)
      }
    }

    generateDeepLink()
  }, [])

  return (
    <div className="flex flex-col justify-center items-center p-2 pt-12">
      <div className="card max-w-6xl shadow-xl flex flex-row flex-wrap">
        {isLoading ? (
          <div className="loading-container">
            <h1 className="text-4xl text-center mb-4">Please Wait..</h1>
            <div className="flex justify-center items-center">
              <div className="animate-spin rounded-full h-24 w-24 border-b-4 border-gray-900"></div>
            </div>
          </div>
        ) : (
          <>
            <div className="flex align container main">
              <img src={checkmark} className="checkmark" />
              <h1 className="header leading-tight font-bold">EVENT JOINED</h1>
              {isMobile ? (
                <div className="mobile-section">
                  <p className="text-content">
                    Check out the details for your new event and start earning
                    points!
                  </p>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={link ?? KR_EVENTS_PAGE}
                    className="get-app-button leading-tight font-bold"
                  >
                    EVENT DETAILS
                  </a>
                </div>
              ) : null}
              <p className="text-content">
                {isMobile
                  ? 'Don’t have the app? Download to start earning points for your event.'
                  : 'You’re so close! Just download our free app to start earning points for your event.'}
              </p>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={link ?? KR_EVENTS_PAGE}
                className="get-app-button leading-tight font-bold"
              >
                GET THE APP
              </a>
              <div className="flex justify-center mb-4">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={
                    isMobile
                      ? link ?? KR_EVENTS_PAGE
                      : 'https://apps.apple.com/us/app/kilter-rewards/id1265336240'
                  }
                >
                  <img className="h-12 mr-1" src={appstore} />
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={
                    isMobile
                      ? link ?? KR_EVENTS_PAGE
                      : 'https://play.google.com/store/apps/details?id=com.kilterrewards.kilterApp&hl=en'
                  }
                >
                  <img className="h-12 ml-1" src={playstore} />
                </a>
              </div>
            </div>
            <div className="flex-1 container screens-container">
              <img src={mobilescreens} className="mobile-screens" />
            </div>
          </>
        )}
      </div>
      <div className="bottom-container">
        <p className="bottom-text">
          Want your activities to go even further? Check out what else is going
          on!
        </p>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={KR_EVENTS_PAGE}
          className="get-app-button leading-tight font-bold"
        >
          SEE MORE
        </a>
      </div>
    </div>
  )
}

export default ChallengeSuccess
