import * as React from 'react'

import { useHistory } from 'react-router'

import qs from 'query-string';

import API, { URLS } from '../../services/api'

const KR_EVENTS_PAGE = `https://www.kilterrewards.com/events`

const DonationConfirm: React.FC = () => {
  const history = useHistory()

  const [errMsg, setErrMsg] = React.useState<string>('')
  const [isLoading, setIsLoading] = React.useState<boolean>(true)

  const params = React.useState(() =>
    qs.parse(history.location.search)
  )[0]

  React.useEffect(() => {
    if (!history.location.search) {
      return
    }

    const updatePayment = async (payload: any) => {
      try {
        await API.post(`${URLS.JG_UPDATE_PAYMENT}`, payload)
      } catch (error: any) {
        error =
          typeof error?.Message === 'string'
            ? error?.Message
            : 'Something went wrong, Please try again'

        setErrMsg(error)
      } finally {
        setIsLoading(false)
      }
    }

    const payload = {
      event_id: Number(params.event_id),
      payment_id: Number(params.payment_id),
      ext_transaction_id: String(params?.jgDonationId),
      reference_id: String(params.reference_id),
    }

    updatePayment(payload)
  }, [history])

  return (
    <div className="flex flex-col justify-center items-center p-2 pt-12">
      <div className="card max-w-6xl shadow-xl flex flex-row flex-wrap">
        {isLoading ? (
          <div className="loading-container">
            <h1 className="text-4xl text-center mb-4">Please Wait..</h1>
            <div className="flex justify-center items-center">
              <div className="animate-spin rounded-full h-24 w-24 border-b-4 border-gray-900"></div>
            </div>
          </div>
        ) : Boolean(errMsg) ? (
          <div className="flex flex-col justify-center items-center w-full">
            <h1 className="text-4xl text-center">Oops!</h1>
            <p className="mt-2 text-2xl text-red-500">{errMsg || ''}</p>
          </div>
        ) : (
          <p className="text-center w-full text-2xl block mb-8 mt-4">
            Thank you for your donation!
          </p>
        )}
      </div>

      {Boolean(errMsg) ? null : (
        <div className="bottom-container">
          <p className="bottom-text">
            Want your activities to go even further? Check out what else is
            going on!
          </p>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={KR_EVENTS_PAGE}
            className="get-app-button leading-tight font-bold"
          >
            SEE MORE
          </a>
        </div>
      )}
    </div>
  )
}

export default DonationConfirm
