import * as React from 'react'

import moment from 'moment'

import { Link } from 'react-router-dom'

const CharityDonation = () => {
  const [challenges] = React.useState<any>([])

  return (
    <div>
      {challenges.map((challenge: any) => (
        <div className="border-2 m-2 rounded-lg p-4" key={challenge.cId}>
          <h1>{challenge.name}</h1>
          <div>
            {moment(challenge.start).format('MMMM D, YYYY')} -{' '}
            {moment(challenge.end).format('MMMM D, YYYY')}
          </div>
          {moment(challenge.end) < moment() ? (
            <div>
              {' '}
              {`${moment(challenge.end).diff(moment(), 'days')} days left`}
            </div>
          ) : (
            ''
          )}
          <div>{`$${challenge.prize_pool}`}</div>
          {/* <div dangerouslySetInnerHTML={{ __html: challenge.description }} /> */}
          {/* <div>{challenge.isLive ? 'live' : ''}</div>
          <div>{challenge.public ? 'public' : ''}</div>
          <div>{challenge.type}</div> */}
          <Link
            to={{
              pathname: `/challenge/${challenge.type}/${challenge.id}/join`,
              state: { challenge },
            }}
            className="button"
          >
            Join
          </Link>
        </div>
      ))}
    </div>
  )
}

export default CharityDonation
