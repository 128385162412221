import * as React from 'react'

import * as Yup from 'yup'

import { Form, Formik } from 'formik'

import { useLocation, useHistory, Link } from 'react-router-dom'

import { useAuth } from '../../contexts/Authentication'

import Button from '../../components/Button'
import FormError from '../../components/FormError'
import InputField from '../../components/InputField'

interface LoginFormValues {
  email: string
  password: string
}

const LoginSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Required'),
  password: Yup.string().required('Required'),
})

const Login = () => {
  const { signIn } = useAuth()

  const history = useHistory()
  const location = useLocation()

  const isUnMounted = React.useRef(false)

  const [isLoading, setIsLoading] = React.useState(false)

  React.useEffect(
    () => () => {
      isUnMounted.current = true
    },
    []
  )

  const handleSignIn = React.useCallback(
    async (values: LoginFormValues, { setErrors }: any) => {
      if (!isUnMounted.current) {
        setIsLoading(true)
      }

      const signedIn = await signIn({
        email: values?.email?.trim(),
        password: values?.password?.trim(),
      })

      if (signedIn) {
        // redirect
        const search = new URLSearchParams(location.search)
        const redirectBack = search.get('redirectBack')

        if (redirectBack) {
          history.replace(redirectBack)
        } else {
          history.replace('/')
        }
      } else {
        setErrors({ form: 'Invalid email/password pair.' })
      }
      if (!isUnMounted.current) {
        setIsLoading(false)
      }
    },
    []
  )

  return (
    <div className="flex justify-center items-center pt-12">
      <div className="card">
        <h1 className="text-2xl uppercase leading-tight font-bold text-center mb-8 tracking-widest underlined-header">
          Sign In
        </h1>
        <Formik
          initialValues={{ email: '', password: '' }}
          validationSchema={LoginSchema}
          onSubmit={handleSignIn}
        >
          <Form>
            <FormError />
            <InputField label="Email" type="text" name="email" />
            <InputField label="Password" type="password" name="password" />
            <p className="-mt-2">
              <Link
                to={`/request-password-reset`}
                className="text-teal-500 hover:text-blue-400"
              >
                Forgot your password?
              </Link>
            </p>
            <div className="field-group mt-10">
              <Button type="submit" isLoading={isLoading}>
                Sign In
              </Button>
            </div>
          </Form>
        </Formik>
        <div className="flex justify-center items-center py-2">
          <p>Don’t have an account yet?</p>
          <Link
            to={`/register${location.search || ''}`}
            className="whitespace-pre font-medium text-teal-500"
          >
            {' '}
            Register Here
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Login
