import * as React from 'react'

import { Route, Redirect, RouteProps } from 'react-router-dom'

import { useAuth } from '../../contexts/Authentication'

const PrivateRoute: React.FC<RouteProps> = ({ children, ...props }) => {
  const { state } = useAuth()

  return (
    <Route
      {...props}
      render={({ location }) => {
        return state.auth.key ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/register',
              search: `?redirectBack=${location.pathname}${location.search}`,
            }}
          />
        )
      }}
    />
  )
}

export default PrivateRoute
